import React, { useEffect, useState } from 'react'
import { Select, Input, Button } from 'antd'
import { Formik, Form } from 'formik'
import axios from 'Utils/cj-axios'
import { intersection } from 'lodash'
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: {
    wait_time: number
    time_format: 'min' | 'hour' | 'day'
    interaction: string
  }
  onUpdateNode: any
}

const WaitIn: React.FC<Props> = (props) => {
  const { onAddNewNode, value, onUpdateNode } = props
  const [interactions, setInteractions] = useState([])
  const defaultValue = { wait_time: 5, time_format: 'min', interaction: undefined }

  useEffect(() => {
    const fetchInteractions = async () => {
      const resp = await axios.get('/customer-journeys/interactions')
      setInteractions(resp.data)
    }

    fetchInteractions()
  }, [])

  const onSubmit = (values: any) => {
    onAddNewNode && onAddNewNode('waitIn', values)
    onUpdateNode && onUpdateNode(values)
  }

  return (
    <div className="WaitComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item time-setter">
              <Input
                className="mr-2 w-1/2"
                onChange={handleChange}
                name="wait_time"
                value={values.wait_time}
              />
              <Select
                onChange={(value) => setFieldValue('time_format', value)}
                // name="time_format"
                className="time-setter-format w-1/2"
                value={values.time_format}
              >
                <Option value="min">mins</Option>
                <Option value="hour">hours</Option>
                <Option value="day">days</Option>
              </Select>
            </div>
            <div>Interaction</div>
            <div>
              <Select
                onChange={(value) => setFieldValue('interaction', value)}
                className="time-setter-format w-full"
                value={values.interaction}
              >
                {interactions.map((item) => {
                  return <Option value={item}>{item}</Option>
                })}
              </Select>
            </div>
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default WaitIn
