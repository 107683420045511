import React, { useState } from 'react'
import { Input, Button } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams } from 'react-router-dom'

import { States } from 'Models/campaign'
import LoadingIcon from 'Components/LoadingIcon'

interface props {
  errors: string
  isSubmitting: boolean
  disablePublish: boolean
  handleCreateCampaign?: () => Promise<void>
  handleUpdateCampaign?: () => Promise<void>
  handlePublishCampaign?: () => Promise<void>
}

const Header: React.FC<props> = ({
  errors,
  isSubmitting,
  disablePublish,
  handleCreateCampaign,
  handleUpdateCampaign,
  handlePublishCampaign,
}) => {
  const { id }: { id: number } = useParams()
  const {
    selected_placement,
    start_time,
    start_date,
    end_time,
    end_date,
    days,
    start_at,
    audience_id,
    content,
    mode,
  } = useStoreState((state: any) => state.campaignModule)

  const {
    name,
    description,
    id: campaign_id,
  } = useStoreState((state: { campaignModule: States }) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const renderError = () => {
    if (!errors) return
    return <div className="p-2 w-full text-gray_1 font-bold bg-negative">{errors}</div>
  }
  const isDisabled = () => {
    if (
      name &&
      content &&
      selected_placement &&
      ((mode == 'oneoff' && start_time) ||
        (mode == 'recurring' && start_time && end_time && days) ||
        (mode == 'recurring' && start_at))
    ) {
      return false
    } else {
      return true
    }
  }

  const renderSaveButton = () => {
    if (id || campaign_id)
      return (
        <Button
          onClick={handleUpdateCampaign}
          disabled={!name || isSubmitting || disablePublish || !selected_placement}
        >
          Save
        </Button>
      )
    return (
      <Button
        onClick={handleCreateCampaign}
        disabled={!name || isSubmitting || disablePublish || !selected_placement}
      >
        Save as draft
      </Button>
    )
  }

  return (
    <>
      {renderError()}
      <div className="flex justify-between items-start w-full bg-gray_1 px-7 py-6 shadow-div_b">
        <div className=" w-5/6">
          <Input
            maxLength={500}
            value={name || undefined}
            onChange={(e) => setState({ key: 'name', value: e.target.value })}
            className="text-3xl p-0 shadow-none border-none mb-2 font-semibold"
            placeholder="Enter campaign name"
          />
          <Input.TextArea
            value={description || undefined}
            onChange={(e) => setState({ key: 'description', value: e.target.value })}
            style={{ fontSize: 16, height: 20, padding: 0, boxShadow: 'none', border: 'none' }}
            placeholder="Enter description..."
          />
        </div>
        <div className="flex gap-2 items-center">
          {isSubmitting && (
            <div className="pr-2">
              <LoadingIcon style={{ width: 20, top: 0 }} />
            </div>
          )}
          {renderSaveButton()}
          <Button
            onClick={handlePublishCampaign}
            // disabled={
            //   !name &&
            //   (isSubmitting || disablePublish) &&
            //   (!start_at || !start_time || !start_date || !end_time || !end_date || !days) &&
            //   !content
            // }
            disabled={isDisabled() || disablePublish || !!id}
            type="primary"
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  )
}

export default Header
