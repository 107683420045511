import React, { useState, useEffect } from 'react'
import { Modal, Button, Tabs, Input, List, Typography, Radio } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'
import type { RadioChangeEvent } from 'antd'
import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import { useStoreActions } from 'easy-peasy'
import './index.scss'
import { CJModalPopup } from 'Components/CJNode'
import {
  webStaticPopupSchema,
  webStaticInitialValues,
  webStaticSchemas,
  webDynamicPopupSchema,
  webDynamicInitialValues,
  webDynamicSchemas,
  getSelectedInitialValue,
  contentFields,
  filterSchemasByFields,
  styleFields,
  getSelectedSchemaFields,
  getStaticInitialValues,
  DEFAULT_FIELDS,
  TABS,
} from './constants'
interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
}

const WebPopup: React.FC<Props> = (props) => {
  const {
    isPopoverVisible,
    value,
    initData,
    setIsPopoverVisible,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  const [popupProps, setPopupProps] = useState<{
    type: string
    yupSchema: any
    initialValues: any
    schema: any
  }>({
    type: 'dynamic',
    yupSchema: webDynamicPopupSchema,
    initialValues: webDynamicInitialValues,
    schema: webDynamicSchemas,
  })
  const [activeTab, setActiveTab] = useState({
    key: 'content',
    label: 'Content',
    fields: DEFAULT_FIELDS,
  })

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'static') {
      setPopupProps({
        type: 'static',
        yupSchema: webStaticPopupSchema,
        initialValues: webStaticInitialValues,
        schema: webStaticSchemas,
      })
    } else {
      setPopupProps({
        type: 'dynamic',
        yupSchema: webDynamicPopupSchema,
        initialValues: webDynamicInitialValues,
        schema: webDynamicSchemas,
      })
    }
  }

  const onTabChange = (key: string) => {
    console.log(key)
  }
  // const { headline, promo_title, promo_content, go_back_button_label, go_back_button_url } =
  //   initData
  const CJPopupModal = React.useContext(CJModalPopup)

  const onCloseModal = () => {
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  const onSubmit = async (values: any) => {
    // post field values and HTML DOM for BE usage
    if (popupProps?.type === 'static') {
      onAddNewNode &&
        (await onAddNewNode('webPopup', getStaticInitialValues(values, webStaticInitialValues)))
      onUpdateNode && (await onUpdateNode(getStaticInitialValues(values, webStaticInitialValues)))
    } else {
      onAddNewNode &&
        (await onAddNewNode('webPopup', getStaticInitialValues(values, webDynamicInitialValues)))
      onUpdateNode && (await onUpdateNode(getStaticInitialValues(values, webDynamicInitialValues)))
    }
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || popupProps?.initialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  useEffect(() => {
    setIsPopoverVisible(false)
    // setIsModalVisible(true)
    CJPopupModal.modalVisibleOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => CJPopupModal.modalVisibleOff()}
      visible={CJPopupModal?.isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={800}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`PopupComponent flex ${props.isRunning ? 'is-running' : ''}`}>
        <div className="PopupInputs">
          <Radio.Group onChange={onChange} value={popupProps?.type} className="mb-4">
            <Radio value={'dynamic'}>Dynamic</Radio>
            <Radio value={'static'}>Static</Radio>
          </Radio.Group>
          {popupProps?.type === 'static' ? (
            <CreativeContent
              onSubmit={onSubmit}
              submitText="Save"
              initValues={value || popupProps?.initialValues}
              validationSchema={popupProps?.yupSchema}
              fields={popupProps?.schema}
            />
          ) : (
            <>
              <div className=" grid grid-cols-3 text-center mb-4">
                {TABS?.map((tab) => {
                  return (
                    <div
                      onClick={() => setActiveTab(tab)}
                      key={tab?.key}
                      className=" font-semibold pb-2 border-b cursor-pointer"
                      style={{ borderColor: activeTab?.key === tab?.key ? '#1890FF' : '#00000026' }}
                    >
                      {tab?.label}
                    </div>
                  )
                })}
              </div>
              <CreativeContent
                onSubmit={onSubmit}
                submitText="Save"
                initValues={value || popupProps?.initialValues}
                validationSchema={popupProps?.yupSchema}
                fields={activeTab?.fields}
                onChange={(name: string, value: string) =>
                  setPreviewData({
                    key: name,
                    value: value,
                  })
                }
              />
              {/* <Tabs.TabPane tab="Styles" key="styles">
                  <CreativeContent
                    onSubmit={onSubmit}
                    submitText="Save"
                    initValues={
                      getSelectedInitialValue(value, styleFields) ||
                      getSelectedInitialValue(popupProps?.initialValues, styleFields)
                    }
                    validationSchema={getSelectedSchemaFields(popupProps?.yupSchema, styleFields)}
                    fields={filterSchemasByFields(popupProps?.schema, styleFields)}
                  />
                </Tabs.TabPane> */}
            </>
          )}
        </div>
        {popupProps?.type === 'static' ? null : (
          <Preview isPreview={true} type={'app_popup'}></Preview>
        )}
      </div>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(WebPopup)
