import React, { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'

import { States } from 'Models/campaign'
import Overview from './Overview'
import Audience from './Audience'
import Content from './Content'
import Schedule from './Schedule'
import Review from './Review'
import { useLocation } from 'react-router-dom'
import ZaloGroup from './ZaloGroup'

const Body = () => {
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')
  const { current_step, selected_placement } = useStoreState(
    (state: { campaignModule: States }) => state.campaignModule
  )

  const renderBody = useMemo(() => {
    switch (current_step) {
      case 0:
        return <Overview />
      case 1:
        if (selected_placement?.code === 'zalo_group') return <ZaloGroup />
        return <Audience />
      case 2:
        return <Content />
      case 3:
        return <Schedule />
      case 4:
        return <Review />
    }
  }, [current_step])

  const renderBodyWithId = useMemo(() => {
    switch (current_step) {
      case 0:
        return <Overview />
      case 1:
        return <Content />
      case 2:
        return <Schedule />
      case 3:
        return <Review />
    }
  }, [current_step])

  return <div className="p-7">{id ? renderBodyWithId : renderBody}</div>
}

export default Body
