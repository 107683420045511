import React, { useEffect, useMemo } from 'react'
import { Skeleton, Space, Select, Typography } from 'antd'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { useDebounce } from 'Hooks'

import FBPreview from './Facebook'
import WebPushPreview from './WebPush'
import KaipassNotiPreview from './Kaipass'
import SMS from './SMS'
import Email from './Email'
import Popup from './Popup'
import Toast from './Toast'
import AppPopupPreview from './AppPopup'
import AppPushPreview from 'Components/PreviewChannels/AppPush'
import OneLifeAppPushPreview from 'Components/PreviewChannels/OnelifeAppPush'
import AppInPagePreview from './AppInPage'

const Preview = (props) => {
  const { isPreview, type, initData } = props
  const renderPreview = useMemo(
    () => (
      <div className="preview">
        {type === 'link_clicks' && <FBPreview {...props} />}
        {type === 'post_engagement' && <FBPreview {...props} />}
        {type === 'web_push' && <WebPushPreview {...props} />}
        {type === 'sms' && <SMS {...props} />}
        {type === 'email' && <Email {...props} />}
        {type === 'popup' && <Popup {...props} />}
        {type === 'app_popup' && <AppPopupPreview {...props} data={initData}></AppPopupPreview>}
        {type === 'toast' && <Toast {...props} />}
        {type === 'kaipass' && <KaipassNotiPreview {...props} />}
        {type === 'ol_app' && <OneLifeAppPushPreview {...props} data={initData} />}
        {type === 'appInPage' && <AppInPagePreview {...props} data={initData} />}
        {type === 'webInPage' && <AppInPagePreview {...props} data={initData} />}
      </div>
    ),
    [initData]
  )

  const debounceRender = useDebounce(renderPreview, 500)

  return (
    <div className={classNames(['preview-container', { 'display-preview': isPreview }])}>
      {debounceRender}
    </div>
  )
}

export default connect((state) => ({
  initData: state.campaign.creativeContents.previewData,
}))(Preview)
