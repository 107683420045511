import React from 'react'
import ZnsByTemplateId from './ZnsByTemplateId'
import TestOnDevice from '../TestOnDevice'
import axios from 'Utils/axios'

export default function ZNSPreview() {
  const handleTest = (payload) => {
    try {
      axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: {
          ...payload.content,
          params: payload.content.params.reduce((acc, item) => {
            acc[item.name] = item.key
            return acc
          }, {}),
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        {/* <div className="platform-select text-brand_primary font-semibold">
            <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
          </div> */}
        <TestOnDevice
          onSubmit={(payload) => {
            handleTest(payload)
          }}
        />
      </div>
      <div className="preview-screen">
        <div>{<ZnsByTemplateId />}</div>
      </div>
    </div>
  )
}
