import { Button, Slider, Typography } from 'antd'
import { Form, Formik, Field } from 'formik'
import React, { PureComponent } from 'react'
import RichTextField from './Fields/RichText'
import StringField from './Fields/String'
import TextArea from './Fields/TextArea'
import SelectField from './Fields/Select'
import CheckBox from './Fields/CheckBox'
import ImageField from './Fields/Image'
import RangeField from './Fields/Range'
import DependentSelects from './Fields/DependentSelects'
import ProductSelects from './Fields/ProductSelects'
import CommonTitle from './CommonTitle'
import ColorPickerComponent from './Fields/ColorPicker'
import './index.scss'
import { connect } from 'react-redux'
import DynamicContent from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/DynamicContent'
import FieldWrapper from './FieldWrapper'
import ButtonSelects from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/ButtonSelects'
const { Title } = Typography

class CreativeContent extends PureComponent {
  state = { fields: this.props.fields }

  componentDidMount() {
    const { fields } = this.props
    const call_to_action_array = fields.filter((ele) => ele.name === 'call_to_action')[0]?.options
    //
    this.props.setOptions(call_to_action_array)
  }

  renderField = (fieldProps, formikProps) => {
    const { type } = fieldProps
    const { onChange } = this.props
    switch (type) {
      case 'string':
        return <StringField {...fieldProps} {...formikProps} onFieldChange={onChange}></StringField>
      case 'hidden':
        return (
          <StringField
            {...fieldProps}
            inputType="hidden"
            {...formikProps}
            onFieldChange={onChange}
          ></StringField>
        )
      case 'color_picker':
        return (
          <ColorPickerComponent
            {...fieldProps}
            {...formikProps}
            onFieldChange={onChange}
          ></ColorPickerComponent>
        )
      case 'rich_text':
        return (
          <RichTextField {...fieldProps} {...formikProps} onFieldChange={onChange}></RichTextField>
        )
      case 'text_area':
        return <TextArea {...fieldProps} {...formikProps} onFieldChange={onChange}></TextArea>
      case 'checkbox':
        return <CheckBox {...fieldProps} {...formikProps} onFieldChange={onChange}></CheckBox>
      case 'select':
        return <SelectField {...fieldProps} {...formikProps} onFieldChange={onChange}></SelectField>
      case 'multi_select':
        return (
          <SelectField
            {...fieldProps}
            {...formikProps}
            multi
            onFieldChange={onChange}
          ></SelectField>
        )
      case 'image':
        return <ImageField {...fieldProps} {...formikProps} onFieldChange={onChange}></ImageField>
      case 'range':
        return (
          <RangeField {...fieldProps} {...formikProps} range onFieldChange={onChange}></RangeField>
        )
      case 'dependent_selects':
        return (
          <DependentSelects
            {...fieldProps}
            {...formikProps}
            range
            onFieldChange={onChange}
          ></DependentSelects>
        )
      case 'product_selects':
        return <ProductSelects {...fieldProps} {...formikProps} multi onFieldChange={onChange} />
      case 'dynamic_content': {
        return (
          <FieldWrapper {...fieldProps} {...formikProps}>
            {() => (
              <>
                <DynamicContent
                  {...fieldProps}
                  {...formikProps}
                  value={formikProps.values[fieldProps.name]}
                  onChange={(value) => {
                    onChange(fieldProps.name, value)
                    formikProps.setFieldValue(fieldProps.name, value)
                  }}
                />
              </>
            )}
          </FieldWrapper>
        )
      }
      case 'button_selects':
        const formattedButtonOptions =
          fieldProps?.options?.map((option) => ({
            value: option.type,
            label: option.label,
            tooltip: option.tooltip,
            sub_options: option.sub_options?.map((sub_option) => ({
              value: sub_option.code,
              label: sub_option.name,
            })),
          })) || []
        return (
          <ButtonSelects
            title="Button"
            value={
              formikProps.values[fieldProps.name] || [
                { option: formattedButtonOptions[0].value, sub_option: '' },
              ]
            }
            options={formattedButtonOptions}
            onChange={(value) => {
              onChange(fieldProps.name, value)
              formikProps.setFieldValue(fieldProps.name, value)
            }}
          />
        )

      default:
        break
    }
  }

  renderFields = (formikProps, fields = this.props.fields) => {
    return fields.map((field) => <div key={field.name}>{this.renderField(field, formikProps)}</div>)
  }

  renderGroupContent = (fieldProps) => {
    const { groups, fields } = this.props
    return groups?.map((g, index) => {
      const fs = g?.fields
        .map((name) => fields.find((field) => field.name === name)) // get field based on group's names
        .filter((field) => field !== undefined) // remove undefined items
      return (
        <div className="form-item">
          <CommonTitle label={g.title} isRequired={g.isRequired} />
          <div className="px-5">{this.renderFields(fieldProps, fs)}</div>
        </div>
      )
    })
  }

  renderCCContent = (fieldProps) => {
    const { groups, fields } = this.props
    if (!groups.length) return this.renderFields(fieldProps)

    const innerGroupFieldNames = groups.map((g) => g.fields).flat()
    const outerGroupFields = fields.filter((f) => !innerGroupFieldNames.includes(f.name))

    return (
      <div>
        {this.renderFields(fieldProps, outerGroupFields)}
        {this.renderGroupContent(fieldProps)}
      </div>
    )
  }

  renderBtnSubmit = () => {
    const { btnSubmit, submitText } = this.props
    if (!btnSubmit) return
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" htmlType="submit" loading={this.props.isSubmitted}>
          {submitText}
        </Button>
      </div>
    )
  }

  onSubmit = (values) => {
    this.props.onSubmit(values)
  }

  render() {
    const { childrenTop, formTitle, initValues, validationSchema, onSubmit } = this.props

    return (
      <div className="CreativeContentContainer">
        <Formik
          innerRef={this.props.innerRef}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          enableReinitialize
          initialValues={initValues}
        >
          {(props) => {
            return (
              <Form
                onError={(err) => {}}
                onSubmit={(e) => {
                  e.preventDefault()
                  props.handleSubmit()
                }}
              >
                {formTitle && (
                  <Title className="page-sub-title" level={5}>
                    {formTitle}
                  </Title>
                )}
                {childrenTop && this.props.children(props)}
                {this.renderCCContent({ ...props, onChange: this.props.onChange })}
                {!childrenTop && this.props.children(props)}
                {onSubmit && this.renderBtnSubmit()}
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

CreativeContent.defaultProps = {
  fields: [],
  initValues: [],
  formTitle: '',
  btnSubmit: true,
  submitText: 'Submit',
  childrenTop: false,
  groups: [],
  onSubmit: () => {},
  children: () => {},
  onChange: () => {},
  validationSchema: {},
}

export default connect(
  (state) => ({
    isSubmitted: state.campaign.creativeContents.isSubmitted,
  }),
  (dispatch) => ({
    setOptions: dispatch.campaign.creativeContents.setOptions,
  })
)(CreativeContent)
