import React, { useEffect, useState, useRef } from 'react'
import { Dropdown, Input, Popover, Select } from 'antd'
import useOnClickOutside from './useOnClickOutside'
import { getTemplateMessages, getMessageParams } from 'Services/store-farming'

import './DynamicContent.scss'
import { useStoreState } from 'easy-peasy'
import { States as CampaignStates } from 'Models/campaign'
import LoadingIcon from 'Components/LoadingIcon'
export type ParamType = { parameter_code: string }

export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}
const DynamicContent = ({ value, onChange, placeholder, defaultValue = '' }) => {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)
  const campaignAudienceId = useStoreState(
    (state: { campaignModule: CampaignStates }) => state.campaignModule.audience_id
  )
  const [params, setParams] = useState<ParametersType[]>([])
  const [templates, setTemplates] = useState<
    { id: number; created_at: string; updated_at: string; content: string }[]
  >([])
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] = useState(false)
  const [selectedDynamic, setSelectDynamic] = useState<any>()
  const [selectedOperator, setSelectedOperator] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [entered, setEntered] = useState(false)
  const isManualCampaign = window.location.pathname.split('/')[1] === 'campaigns'
  const ref = useRef()
  useOnClickOutside(ref, () => {
    !entered && setVisible(false)
  })

  const handleFetchData = async (campaignAudienceId) => {
    const paramsResp = await getMessageParams(campaignAudienceId)
    const message_templates = await getTemplateMessages()
    const paramData: ParametersType[] = paramsResp.data

    selected_placement?.code == 'sms'
      ? setParams([
          ...paramData,
          {
            group_code: 'sms',
            group_name: 'Coupon Code',
            parameters: [
              { parameter_code: '{{coupon_code}}' },
              { parameter_code: '{{coupon_value}}' },
              { parameter_code: '{{coupon_expire_date}}' },
            ],
          },
        ])
      : setParams(paramData)
    setTemplates(message_templates.data.template_messages)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    handleFetchData(campaignAudienceId)
  }, [])
  function transformData(input) {
    const formattedArray: any = []

    for (const key in input) {
      if (input?.hasOwnProperty(key)) {
        const operatorArray: any = []

        for (const operatorKey in input[key]) {
          if (input[key]?.hasOwnProperty(operatorKey)) {
            operatorArray?.push({
              key: operatorKey,
              value: input[key][operatorKey],
            })
          }
        }

        formattedArray?.push({
          key: key,
          operator: operatorArray,
        })
      }
    }

    return formattedArray
  }

  const dynamicContent = (
    <div className="flex gap-2 items-center justify-center w-fit h-fit" style={{ zIndex: 99999 }}>
      <div>{selectedDynamic?.key}</div>
      <Select
        placeholder="Operator"
        onChange={(i) => {
          setSelectedOperator(
            selectedDynamic?.operator?.filter((item: any) => item?.key === i)?.[0]?.value
          )
        }}
        options={selectedDynamic?.operator?.map((i: any) => {
          return {
            value: i?.key,
            label: i?.key,
          }
        })}
      />
      <Select
        placeholder="Value"
        onChange={(e, ev) => {
          onChange(`${value || ''} ${e}`)
          setEntered(false)
          setSelectDynamic(null)
          setVisible(false)
        }}
        style={{ minWidth: '300px' }}
        options={selectedOperator?.map((i: any) => {
          return {
            value: i,
            label: i,
          }
        })}
      />
    </div>
  )

  const renderParamDropdown = (
    <div ref={ref as any}>
      {loading ? (
        <div className=" bg-white flex items-center justify-center">
          {' '}
          <LoadingIcon></LoadingIcon>{' '}
        </div>
      ) : (
        <div
          className="p-2 bg-gray_1 grid gap-10 grid-cols-4 gap-4 shadow-lg"
          style={{ borderRadius: 4 }}
        >
          {params?.map((param) => (
            <div key={param.group_code}>
              <h3 className="font-bold">{param?.group_name}</h3>
              {!param?.group_code?.includes('dynamic') ? (
                <div>
                  {param?.parameters?.map((item) => (
                    <div
                      className="cursor-pointer hover:text-brand_primary my-2"
                      onClick={() => {
                        onChange(`${value || ''} ${item?.parameter_code}`)
                        setVisible(false)
                      }}
                      key={item?.parameter_code}
                    >
                      {item?.parameter_code}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {transformData(param?.parameters)?.map((item) => (
                    <div
                      className="cursor-pointer hover:text-brand_primary my-2"
                      onClick={(e) => {
                        setSelectDynamic(item)
                        setEntered(true)
                      }}
                      key={item?.key}
                    >
                      <Popover
                        onVisibleChange={(e) => setEntered(e)}
                        content={dynamicContent}
                        title={item?.key}
                        trigger="click"
                        destroyTooltipOnHide
                        placement="left"
                        visible={visible && entered && selectedDynamic?.key === item?.key}
                      >
                        <div>{item.key}</div>
                      </Popover>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )

  const renderTemplateDropdown = (
    <div>
      {loading ? (
        <div className="bg-white  flex items-center justify-center">
          {' '}
          <LoadingIcon></LoadingIcon>{' '}
        </div>
      ) : (
        <div className="template-message-wrapper">
          <div>
            {templates.map((message) => (
              <div
                onClick={() => {
                  setIsTemplateDropdownVisible(!isTemplateDropdownVisible)
                  onChange(message.content)
                }}
                className="template-message-item"
                key={message.id}
              >
                {message.content}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className="DynamicContentComponent">
      <div className="dropdown-wrapper">
        <div className="__action-btn add-param">
          <Dropdown
            overlayClassName={`${isManualCampaign ? 'z-40' : ''} shadow-bot_2 border rounded`}
            overlay={renderParamDropdown}
            // trigger={['click']}
            placement="bottomCenter"
            visible={visible}
          >
            <div onClick={() => setVisible(true)}>Add parameter</div>
          </Dropdown>
        </div>
        <div className="text-brand_primary">|</div>
        <div className="__action-btn use-template">
          <Dropdown
            visible={isTemplateDropdownVisible}
            overlay={renderTemplateDropdown}
            trigger={['click']}
          >
            <div onClick={() => setIsTemplateDropdownVisible(!isTemplateDropdownVisible)}>
              Use template
            </div>
          </Dropdown>
        </div>
      </div>
      <Input.TextArea
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        rows={3}
      />
      {selected_placement?.code == 'sms' && (
        <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', paddingTop: 8 }}>
          A single message contains 160 characters
        </div>
      )}
    </div>
  )
}

export default DynamicContent
