export const TIME_VALUE_OPTIONS = [
  {
    name: 'Minutes',
    code: 'min',
  },
  {
    name: 'Hours',
    code: 'hour',
  },
  {
    name: 'Days',
    code: 'day',
  },
]

export const APP_SCREENS_OPTIONS = [
  {
    name: 'App Home',
    code: 'APP_HOME',
  },
  {
    name: 'App Search Result',
    code: 'APP_SEARCH_RESULT',
  },
  {
    name: 'App Product Detail',
    code: 'APP_PRODUCT_DETAIL',
  },
  {
    name: 'App Product Listing',
    code: 'APP_PRODUCT_LISTING',
  },
  {
    name: 'App Cart',
    code: 'APP_CART',
  },
  {
    name: 'App Topup Card',
    code: 'APP_TOPUP_CARD',
  },
  {
    name: 'App Reward Listing',
    code: 'APP_REWARD_LISTING',
  },
  {
    name: 'App Tab Card',
    code: 'APP_TAB_CARD',
  },
]

export const WEB_SCREENS_OPTIONS = [
  {
    name: 'Web Home',
    code: 'WEB_HOME',
  },
  {
    name: 'Web Search Result',
    code: 'WEB_SEARCH_RESULT',
  },
  {
    name: 'Web Product Detail',
    code: 'WEB_PRODUCT_DETAIL',
  },
  {
    name: 'Web Product Listing',
    code: 'WEB_PRODUCT_LISTING',
  },
  {
    name: 'Web Cart',
    code: 'WEB_CART',
  },
  {
    name: 'Web Topup Card',
    code: 'WEB_TOPUP_CARD',
  },
  {
    name: 'Web Reward Listing',
    code: 'WEB_REWARD_LISTING',
  },
  {
    name: 'Web Tab Card',
    code: 'WEB_TAB_CARD',
  },
]
