import React from 'react'

// Operator mapping
const operatorSymbols = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  IS_BETWEEN: 'between', // Special handling needed
  IS_NOT_BETWEEN: 'not between', // Special handling needed
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
  STARTS_WITH: 'starts with',
  DOES_NOT_START_WITH: 'does not start with',
  ENDS_WITH: 'ends with',
  DOES_NOT_END_WITH: 'does not end with',
  IN_THE_FOLLOWING: 'in the following',
  NOT_IN_THE_FOLLOWING: 'not in the following',
}

const toTitleCase = (str) => {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase
    ?.split('_') // Split the string by underscores
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize the first letter of each word
    ?.join(' ') // Join the words with a space
}

// Function to format conditions
export const formatCartValueConditions = (conditions) => {
  return conditions
    ?.map((condition) => {
      const operator = operatorSymbols[condition?.operator]
      if (!operator) {
        throw new Error('Unknown operator')
      }
      if (operator === 'between' || operator === 'not between') {
        // Special handling for "between" and "not between"
        return `${toTitleCase(condition?.type)} ${operator} ${condition?.value} ${
          condition?.time_operator
        } ${condition?.time_value}`
      }
      return `${toTitleCase(condition?.type)} ${operator} ${condition?.value || ''} ${
        condition?.time_operator || ''
      } ${condition?.time_value || ''}`
    })
    ?.join(' and ')
}

export function camelCaseToNormalText(camelCaseText) {
  // Add a space before any uppercase letter and convert the first letter to uppercase
  let result = camelCaseText
    ?.replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    ?.replace(/^./, function (str) {
      return str?.toUpperCase()
    }) // Capitalize the first letter

  // Replace "Entrance" with "Event"
  result = result?.replace(/Entrance/g, 'Event')

  return result
}
