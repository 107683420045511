import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { getZnsTemplateById } from 'Services/campaigns'
import KFMLogo from 'Assets/images/LogoKFMPreview.png'
import { Button, Modal, Skeleton } from 'antd'
import { RiPhoneLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AppPushParams } from 'Constants/ChannelSchemas/AppPush.schema'
import { CurrentType } from 'CustomerJourneyModule'
import Header from 'Components/CJHeader'
import { connect } from 'react-redux'
import FormItemZNS from './FormItemZNS'
import axios from 'Utils/axios'
import TestOnDevice from 'Components/PreviewChannels/Preview/TestOnDevice'
import { getZnsTemplateByIdNew } from 'Services/new-cj'
import ZnsByTemplateId from 'Components/PreviewChannels/Preview/ZNSByTemplateId/ZnsByTemplateId'

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}
const ZaloGroup: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    initData,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const { content } = useStoreState((state) => state.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>()
  const [audienceId, setAudienceId] = useState()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  const { CJTree } = useStoreState((states) => states.customerJourney)
  function findNodeByType(tree, targetType) {
    if (!tree) {
      return null // If the tree is null or undefined, return null
    }

    // Check if the current node matches the target nodeType
    if (tree.nodeType === targetType) {
      return tree
    }

    // If the node has children, search through each child recursively
    if (tree?.parentNode) {
      const result = findNodeByType(tree?.parentNode, targetType)
      if (result) {
        return result // Return the found node if it matches
      }
    }

    // Return null if the node is not found
    return null
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value })
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const handleTest = (payload) => {
    try {
      axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: {
          ...payload.content,
          params: payload.content.params.reduce((acc, item) => {
            acc[item.name] = item.key
            return acc
          }, {}),
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = async (values: any) => {
    onAddNewNode && (await onAddNewNode('znsByTemplateId', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateByIdNew()
        setTemplateId(resp?.data?.templates)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    getTemplate()
  }, [templateId?.length])
  useEffect(() => {
    const selectedTemp = templateId?.filter((i) => i.template_id === content?.template_id)
    if (selectedTemp?.length) {
      setSelectedTemplateId({ ...selectedTemp[0] })
    }
  }, [content])
  useEffect(() => {
    setAudienceId(
      findNodeByType(props, 'checkAudienceSegment')?.value?.audience_segment_id ||
        CJTree?.childrenNodes?.[0]?.value?.audience_segment_id
    )
  }, [props, CJTree])
  if (loadingTemplate)
    return (
      <div style={{ width: '400px' }}>
        <Skeleton active></Skeleton>
      </div>
    )
  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={null}
      width={1100}
      closable={false}
      className="ChannelsModal"
    >
      <div className="w-full flex gap-4" id="CampaignContent">
        <div className="w-1/2 bg-gray_1 p-4 rounded overflow-scroll max-h-[70vh]">
          <FormItemZNS onSubmit={onSubmit} audience_id={audienceId} value={value} />
        </div>
        <div className="w-1/2 bg-gray_1 p-4 rounded border mx-auto h-fit flex justify-center">
          <div className="w-full" id="ContentPreview">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-lg">Preview</span>
              {/* <div className="platform-select text-brand_primary font-semibold">
            <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
          </div> */}
              <TestOnDevice
                onSubmit={(payload) => {
                  handleTest(payload)
                }}
              />
            </div>
            <div className="preview-screen">
              <div>{<ZnsByTemplateId />}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(ZaloGroup)
