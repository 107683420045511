import React from 'react'
import { ReactComponent as IconCheckBoxCircleFill } from 'Assets/images/icons/checkboxcirclefill.svg'
import { ReactComponent as ManualTargetPush } from 'Assets/images/icons/iconPlacements/manualtargetpush.svg'
import { ReactComponent as OnsiteExitIntent } from 'Assets/images/icons/iconPlacements/onsiteexitintent.svg'
import { ReactComponent as OnsiteRecentViewed } from 'Assets/images/icons/iconPlacements/onsiterecentviewed.svg'
import { ReactComponent as OnsiteSocialProof } from 'Assets/images/icons/iconPlacements/onsitesocialproof.svg'
import { ReactComponent as PopupEmailSubscribe } from 'Assets/images/icons/iconPlacements/popupemailsubscribe.svg'
import { ReactComponent as PopupFeedback } from 'Assets/images/icons/iconPlacements/popupfeedback.svg'
import { ReactComponent as Kaipass } from 'Assets/images/icons/iconPlacements/kaipass.svg'
import { ReactComponent as MarketingNews } from 'Assets/images/icons/iconPlacements/marketingnews.svg'
import { ReactComponent as OrderTrackingNotification } from 'Assets/images/icons/iconPlacements/ordertrackingnotification.svg'
import { ReactComponent as LinkClicks } from 'Assets/images/icons/iconPlacements/linkclicks.svg'
import { ReactComponent as Post } from 'Assets/images/icons/iconPlacements/post.svg'
import { ReactComponent as StoreFarming } from 'Assets/images/icons/iconPlacements/storefarming.svg'
import { ReactComponent as Zalo } from 'Assets/images/icons/iconPlacements/zalo.svg'
import { ReactComponent as Facebook } from 'Assets/images/icons/iconPlacements/fb.svg'
import { ReactComponent as TheCoffeeHouse } from 'Assets/images/icons/iconPlacements/tch.svg'
import { ReactComponent as SMS } from 'Assets/images/icons/iconPlacements/sms.svg'
import { AimOutlined } from '@ant-design/icons'

export const computeIcon = (placementCode) => {
  switch (placementCode) {
    case 'manual_targeted_push':
    case 'web_push':
      return <ManualTargetPush className="mr-4" />
    case 'onsite_exit_intent':
      return <OnsiteExitIntent className="mr-4" />
    case 'onsite_recent_viewed':
      return <OnsiteRecentViewed className="mr-4" />
    case 'onsite_social_proof':
      return <OnsiteSocialProof className="mr-4" />
    case 'popup_email_subscribe':
      return <PopupEmailSubscribe className="mr-4" />
    case 'popup_feedback':
      return <PopupFeedback className="mr-4" />
    case 'kaipass':
      return <Kaipass className="mr-4" />
    case 'marketing_news':
      return <MarketingNews className="mr-4" />
    case 'order_marketing_notification':
      return <OrderTrackingNotification className="mr-4" />
    case 'link_clicks':
      return <LinkClicks className="mr-4" />
    case 'post':
      return <Post className="mr-4" />
    case 'store_farming':
      return <StoreFarming className="mr-4" />
    case 'zalo':
      return <Zalo className="mr-4" />
    case 'zns_by_template_id':
      return <Zalo className="mr-4" />
    case 'zalo_group':
      return <Zalo className="mr-4" />
    case 'facebook':
      return <Facebook className="mr-4" />
    case 'the_coffee_house':
      return <TheCoffeeHouse className="mr-4" />
    case 'sms':
      return <SMS className="mr-4" />
    default:
      return <AimOutlined className="text-brand_primary text-base mr-4" />
  }
}

export const computeDescription = (placementCode) => {
  switch (placementCode) {
    case 'manual_targeted_push':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'onsite_exit_intent':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'onsite_recent_viewed':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'onsite_social_proof':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'popup_email_subscribe':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'popup_feedback':
      return 'Notify users about sales, important updates or new content even after they leave your site'
    case 'kaipass':
      return `Engage Kaipass app's active users by sending them targeted, contextual messages`
    case 'marketing_news':
      return 'The quickest way to send push notifications to all your users. '
    case 'order_marketing_notification':
      return 'Notify the customers about their order status via app push notifications'
    case 'email':
      return `Delivering your transactional and marketing emails`
    case 'link_clicks':
      return 'Create Link clicks Facebook ads via Facebook API'
    case 'post':
      return 'Create Facebook Post Engagement via Facebook API'
    case 'store_farming':
      return "Assign a store's staffs to engage with its customers."
    case 'zalo':
      return 'Engage customers by sending them targeted, contextual messages via Zalo'
    case 'facebook':
      return 'The quickest way to send push notifications to all your users. '
    case 'the_coffee_house':
      return `Engage your app's active users by sending them targeted, contextual messages`
    default:
      return 'The quickest way to send push notifications to all your users. '
  }
}
