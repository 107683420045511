import { Button, Checkbox, Input, Modal } from 'antd'
import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'

import { RiSmartphoneLine } from 'react-icons/ri'
import useTestOnDevice from './useTestOnDevice'
import TestZaloGroup from './TestZaloGroup'

export default function TestOnDevice({
  isZaloGroup,
  onSubmit,
}: {
  isZaloGroup?: boolean
  onSubmit?: (payload: Record<string, string>) => void
}) {
  const {
    devices,
    handleTest,
    onSelect,
    onAddDevice,
    onRemove,
    setOpen,
    open,
    setInput,
    input,
    setIsEditing,
    isEditing,
  } = useTestOnDevice({ onSubmit })

  return (
    <div>
      <div
        style={{
          color: 'var(--brand_primary)',
          fontSize: 14,
          fontWeight: 600,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          gap: '4px',
          alignItems: 'center',
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <RiSmartphoneLine />
        Test on Device
      </div>
      <Modal
        footer={null}
        title="Test on Device"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        visible={open}
      >
        {(() => {
          console.log(isZaloGroup)
          if (isZaloGroup) return <TestZaloGroup />

          return (
            <div>
              {isEditing ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Input
                    style={{ marginRight: '8px' }}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <Button
                    className="p-0"
                    style={{
                      width: 33,
                      color: 'var(--brand_primary)',
                      fontWeight: 600,
                    }}
                    disabled={!input}
                    type="text"
                    onClick={() => {
                      onAddDevice(input)
                      setInput('')
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsEditing(true)
                  }}
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    color: 'var(--brand_primary)',
                    cursor: 'pointer',
                  }}
                >
                  Add phone number
                </div>
              )}

              {devices?.length ? (
                <div>
                  <div style={{ margin: '8px 0' }}>Saved devices</div>
                  {devices.map((device, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: '8px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid black',
                          borderColor: '#F0F0F0',
                        }}
                      >
                        <div>
                          <Checkbox
                            checked={device.selected}
                            onChange={(e) => {
                              onSelect({ phone: device.phone, selected: e.target.checked }, index)
                            }}
                          />
                          <span style={{ paddingLeft: '8px' }}>{device.phone}</span>
                        </div>
                        <div>
                          <RiDeleteBin6Line
                            onClick={() => {
                              onRemove(index)
                            }}
                            style={{ width: 18, height: 18 }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : null}
              <Button
                onClick={handleTest}
                style={{ marginTop: '20px' }}
                block
                size="large"
                type="primary"
              >
                Test
              </Button>
            </div>
          )
        })()}
      </Modal>
    </div>
  )
}
