import React from 'react'
import { AutoComplete, Form, Input, message, Select, Skeleton } from 'antd'
import { Schema } from 'CampaignTypes'
import ChannelSchemas from 'Constants/ChannelSchemas'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { getParameterSegment, getZnsTemplateById } from 'Services/campaigns'
import { getMessageParams } from 'Services/store-farming'
import FormItem from './FormItem'
export type ParamType = { parameter_code: string }
export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}

const FormItemZNS = () => {
  const [form] = Form.useForm()
  const { content, audience_id } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [dimensionListing, setDimensionListing] = useState<string[]>([])
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [loadingDimension, setLoadingDimension] = useState(false)
  const [params, setParams] = useState<any[]>([])
  const handleFetchData = async () => {
    const paramsResp = await getMessageParams()
    const paramData: ParametersType[] = paramsResp.data
    const flat = paramData?.map((item) => {
      return item.parameters.map((i) => i.parameter_code?.replace(/{{|}}/gi, ''))
    })
    var listDefaultDimensions: any = []
    flat?.forEach((item) => {
      listDefaultDimensions = [...listDefaultDimensions, ...item]
    })
    setParams(listDefaultDimensions)
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  useEffect(() => {
    const getDimensionListing = async () => {
      setLoadingDimension(true)
      try {
        const resp = await getParameterSegment(audience_id)
        const filterKey = resp?.data?.parameters?.filter(
          (item) => ![...dimensionListing, ...params].includes(item)
        )
        setDimensionListing([...dimensionListing, ...filterKey])
        // setState({
        //   key: 'content',
        //   value: { ...content, params: content?.params?.length ? content?.params : [] },
        // })
      } catch (err: any) {
        setLoadingDimension(false)
        setDimensionListing([])
        console.log('something went wrong!')
      }
      setLoadingDimension(false)
    }
    getDimensionListing()
  }, [audience_id])

  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateById()
        setTemplateId(resp?.data?.templates)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    getTemplate()
  }, [selectedTemplateId])

  useEffect(() => {
    if (!content?.template_id && templateId?.length) {
      form.setFieldsValue({ template_id: templateId[0]?.template_id })
      setSelectedTemplateId(templateId[0]?.template_id)
      setState({
        key: 'content',
        value: {
          ...content,
          params: content?.params?.length ? content?.params : [],
          template_id: templateId[0]?.template_id,
          template_name: templateId[0]?.template_name,
        },
      })
    } else {
      setSelectedTemplateId(content?.template_id)
      setState({
        key: 'content',
        value: {
          ...content,
          params: content?.params?.length ? content?.params : [],
          template_id: content?.template_id,
          template_name: content?.template_name,
        },
      })
    }
  }, [templateId, loadingTemplate])

  useEffect(() => {
    if (templateId?.length) {
      setState({
        key: 'content',
        value: {
          ...content,
          template_name: templateId?.filter((item) => item.template_id === selectedTemplateId)[0]
            .template_name,
        },
      })
    }
  }, [selectedTemplateId])

  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
  }

  const onChangeValue = (name: string, value?: string) => {
    setSelectedTemplateId(value)
    form.setFieldsValue({ [name]: value })

    setState({ key: 'isContentValidated', value: false })
    setState({ key: 'content', value: { ...content, [name]: value, params: [] } })
  }

  const onChangeDimension = (key, value) => {
    form.setFieldsValue({ [key]: value })
    if (content?.params?.filter((item) => item.name === key)?.length) {
      const filterContent = content?.params?.filter((i) => i.name !== key)
      const params = [...filterContent, { key: value, name: key }]
      setState({ key: 'content', value: { ...content, params } })
    } else {
      const params = [...content?.params, { key: value, name: key }]
      setState({ key: 'content', value: { ...content, params } })
    }
  }
  const getValueContentParams = (name) => {
    const getItem = content?.params?.filter((item) => item.name === name)

    if (getItem?.length) {
      return getItem[0]?.key
    } else {
      return undefined
    }
  }
  if (loadingTemplate)
    return (
      <>
        <Skeleton active></Skeleton>
      </>
    )
  return (
    <div>
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={content}
        scrollToFirstError
        form={form}
      >
        <Form.Item key={'template_id'} label={'Template ID'} name={'template_id'}>
          <Select
            value={form.getFieldValue(content && content['template_id']) || selectedTemplateId}
            onChange={(value) => onChangeValue('template_id', value)}
            options={templateId?.map((item) => ({
              value: item?.template_id || '',
              label: item?.template_id || '',
            }))}
          />
        </Form.Item>
        <Form.Item key={'template_name'} label={'Template Name'}>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }} className="px-4 py-2 rounded ">
            {content?.template_name || ''}
          </div>
        </Form.Item>
        {loadingDimension ? (
          <Skeleton active></Skeleton>
        ) : (
          templateId?.length &&
          templateId
            ?.filter((i) => i.template_id === selectedTemplateId)[0]
            ?.params.map((item, index) => (
              <Form.Item key={item?.name} label={`parameter ${index + 1}`}>
                <Input disabled value={item?.name} />
                <AutoComplete
                  onChange={(value) => onChangeDimension(item?.key, value)}
                  value={getValueContentParams(item.key)}
                  options={dimensionListing?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Form.Item>
            ))
        )}
        {ChannelSchemas.zns_by_template_id.map((schema: Schema) => (
          <Form.Item
            key={schema.name}
            label={schema.label}
            tooltip={schema.tooltip}
            className={schema.className || ''}
            name={schema.name}
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues[schema.name] !== currentValues[schema.name]) return true
              return false
            }}
            rules={[
              ...(schema.isRequired
                ? [
                    {
                      required: schema.isRequired,
                      message: `${schema.label} is required`,
                    },
                  ]
                : []),
              ...(schema.validatePattern
                ? [
                    {
                      pattern: schema.validatePattern,
                      message: `${schema.label} doesn't match the pattern`,
                    },
                  ]
                : []),
            ]}
            initialValue={schema.defaultValue}
          >
            <FormItem
              name={schema?.name}
              type={schema?.type}
              value={form.getFieldValue(content && content[schema?.name])}
              options={schema.options}
              placeholder={schema.placeholder || ''}
              onChange={(name, value) => {
                form.setFieldsValue({ [name]: value })
                setState({ key: 'isContentValidated', value: false })
                setState({ key: 'content', value: { ...content, [name]: value } })
              }}
            />
          </Form.Item>
        ))}
      </Form>
    </div>
  )
}

export default FormItemZNS
