import { useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { getZnsTemplateById } from 'Services/campaigns'
import KFMLogo from 'Assets/images/LogoKFMPreview.png'
import { Button, Skeleton } from 'antd'
import { RiPhoneLine } from 'react-icons/ri'

const ZnsByTemplateId = () => {
  const { content } = useStoreState((state) => state.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>()
  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateById()
        setTemplateId(resp?.data?.templates)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    getTemplate()
  }, [templateId?.length])
  useEffect(() => {
    const selectedTemp = templateId?.filter((i) => i.template_id === content?.template_id)
    if (selectedTemp?.length) {
      setSelectedTemplateId({ ...selectedTemp[0] })
    }
  }, [content])
  if (loadingTemplate)
    return (
      <div style={{ width: '400px' }}>
        <Skeleton active></Skeleton>
      </div>
    )
  return (
    <div style={{ maxWidth: 400 }} className="">
      <div className="bg-white">
        <div className="p-4">
          <img src={KFMLogo} alt="" />
        </div>
        <div className="px-4">
          <div className="content">
            {selectedTemplateId?.content?.split('\\n')?.map((line, index) => (
              <React.Fragment key={index}>
                <div className="pt-3">{line}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="p-4">
          <div className="py-2 w-full bg-brand_primary text-white text-base rounded text-center font-semibold">
            <div className="flex items-center justify-center">
              <RiPhoneLine size={18} /> <div className="ml-2">Liên hệ CSKH</div>
            </div>
          </div>
          <div className="py-2 w-full bg-brand_light text-brand_accent text-base rounded text-center mt-2 font-semibold">
            Tìm hiểu thêm
          </div>
        </div>
      </div>
    </div>
  )
}

export default ZnsByTemplateId
