import React, { useEffect, useState } from 'react'
import { Typography, Divider, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import NodeStats from './NodeStats'
import { hexToRgbA } from 'Utils'
import axios from 'Utils/axios'
import NodePreview from './NodePreview'
import { type } from 'jquery'
import { useStoreState } from 'easy-peasy'

const { Title, Text } = Typography

const SquareNode = (props) => {
  const {
    width,
    height,
    bgColor,
    name,
    icon,
    nodeType,
    nodeValue,
    CJStates,
    description,
    stay_in,
    failed,
    passed,
    reached,
  } = props
  const { CJTree } = useStoreState((states) => states.customerJourney)
  function findNodePosition(node, nodeUuid, index = { count: 0 }) {
    // If the current node is the one we're looking for, return its position
    if (node?.nodeUuid === nodeUuid) {
      return index?.count + 1
    }

    // If the current node is not "virtual", increase the count
    if (node.nodeType !== 'virtual') {
      index.count++
    }

    // Traverse the childrenNodes if they exist
    if (node.childrenNodes && node.childrenNodes.length > 0) {
      for (let i = 0; i < node.childrenNodes.length; i++) {
        const position = findNodePosition(node.childrenNodes[i], nodeUuid, index)
        if (position !== undefined) {
          return position
        }
      }
    }

    // Return undefined if the nodeUuid was not found in this branch
    return undefined
  }
  // console.log('node: ', props?.nodeType, 'position: ', findNodePosition(CJTree, props?.nodeUuid))
  const [nodeStatus, setNodeStatus] = useState({})
  const [audienceNode, setAudienceNode] = useState()
  const lightenColor = (hex, percent) => {
    // Remove the leading '#' if present
    hex = hex.replace(/^#/, '')

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    // Calculate the new r, g, b values
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)))
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)))
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)))

    // Convert the new r, g, b values to hex
    r = r.toString(16).padStart(2, '0')
    g = g.toString(16).padStart(2, '0')
    b = b.toString(16).padStart(2, '0')

    // Return the new hex color
    return `#${r}${g}${b}`
  }
  const getSegmentDetail = async () => {
    const resp = await axios.get(`audience-segments/${nodeValue?.audience_segment_id}`)
    setAudienceNode({
      name: resp?.data?.name,
      total_audience: resp?.data?.total_audience,
    })
  }

  useEffect(() => {
    if (nodeType == 'checkAudienceSegment') {
      nodeValue?.audienceSegmentId
        ? setNodeStatus({ segment: 'CUSTOM' })
        : setNodeStatus({ segment: 'ALL' })
    }
    if (nodeType == 'checkAudienceSegment') {
      getSegmentDetail()
    }
  }, [nodeValue])

  return (
    <foreignObject
      style={{
        boxShadow: '0px 2px 8px 0px #0000001F',
        border: '1px solid #00000026',
        borderRadius: '4px',
      }}
      // style={{ filter: `drop-shadow(1px 5px 8px ${hexToRgbA(bgColor, 0.3)}` }}
      className="NodeComponent Square lighting-off"
      // width={'256px'}
      width={width}
      height={height}
    >
      <div className="node-content">
        <div className="px-3">
          <div className="node-body mt-3">
            <div>
              <span
                style={{
                  color: bgColor,
                  backgroundColor: lightenColor(bgColor, 70),
                  marginRight: '8px',
                }}
                className="icon"
              >
                {icon}
              </span>
              <span
                className="name"
                style={{
                  fontWeight: 700,
                  // color: bgColor,
                  // fontSize: '16px',
                  fontSize: Object.keys(nodeStatus)?.length || name?.length > 10 ? '14px' : '16px',
                }}
              >
                {name}
              </span>
            </div>
            <Tooltip title={description}>
              {/* <div style={{ color: bgColor }} className="node-info-icon"> */}
              <div className="node-info-icon">
                <QuestionCircleOutlined />
              </div>
            </Tooltip>
            {/* {process.env.NODE_ENV === 'development' && nodeUuid?.slice(-4)} */}
          </div>
          <NodePreview
            data={nodeValue}
            type={nodeType}
            audienceName={audienceNode?.name}
          ></NodePreview>
        </div>

        <div className="node-footer">
          {/* <Divider className="mb-1 mt-0" /> */}
          {/* {!['running'].includes(CJStates.status) && (
            <div className="status">
              {Object.keys(nodeStatus).map((item) => {
                return (
                  <>
                    <span className="status-key">{item}: </span>
                    <span className="status-value">
                      {nodeType === 'checkAudienceSegment'
                        ? nodeValue?.audience_segment_id || 'all'
                        : nodeStatus[item].toLowerCase()}
                    </span>
                  </>
                )
              })}
            </div>
          )} */}
          {/* {!['running'].includes(CJStates.status) ? null : ( */}
          <div
            className={`${
              nodeType == 'checkAudienceSegment' ? 'justify-between' : 'justify-center'
            } flex  items-center`}
          >
            {nodeType == 'checkAudienceSegment' ? (
              <div>
                <span className=" font-semibold mr-1 text-sm flex-1">
                  {audienceNode?.total_audience || '--'}
                </span>
                <span>audience</span>
              </div>
            ) : null}
            <div>
              <NodeStats {...props} type="reached" value={reached} />
            </div>
            {/* <Tooltip title={description}>
            <div style={{ color: bgColor }} className="node-info-icon">
              <QuestionCircleOutlined />
            </div>
          </Tooltip> */}
          </div>
          {/* )} */}
          <div className="node-footer-stats">
            <NodeStats {...props} type="failed" value={failed} />
            <NodeStats {...props} type="passed" value={passed} />
          </div>
        </div>
      </div>
    </foreignObject>
  )
}

export default connect(
  (state) => ({
    CJStates: state.customerJourney,
  }),
  (dispatch) => ({
    setCJStates: dispatch.customerJourney.setCJStates,
  })
)(SquareNode)
