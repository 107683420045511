import React from 'react'
import { DEVICE_INFO } from '../constants'
import BackgroundLockScreen from 'Assets/images/LookScreenIP.png'
import BackgroundLockScreenAndroid from 'Assets/images/LockscreenAndroid.jpg'
import { ReactComponent as IconOnelife } from 'Assets/images/LogoOnelife.svg'
import { brandVars } from 'Utils/brandVars'
import { RiArrowDownSLine } from 'react-icons/ri'
const LockScreenView = ({ status, device, data }) => {
  const { title, image, content, cta, icon } = data
  if (device.os === 'ios') {
    return (
      <div
        className="bg-no-repeat bg-contain"
        style={{
          padding: '0 10px',
          height: DEVICE_INFO[device.code].height,
          width: DEVICE_INFO[device.code].width,
          backgroundImage: `url(${BackgroundLockScreen})`,
        }}
      >
        <div style={{ paddingTop: '264px' }}>
          <div
            className="flex items-center p-3 "
            style={{
              borderRadius: 18,
              background: 'rgba(245, 245, 245, 0.6)',
              backdropFilter: 'blur(16.601px)',
              maxHeight: '124px',
              overflow: 'hidden',
            }}
          >
            <div style={{ width: '38px', height: '38px' }} className="mr-3">
              {icon ? <img src={icon} alt="" /> : <IconOnelife />}
            </div>
            <div className="flex-1">
              {/* <div className="flex items-center justify-between "> */}
              <div className="grid grid-cols-3">
                <div
                  className="font-semibold truncate col-span-2"
                  style={{ fontSize: '15px', lineHeight: '22px' }}
                >
                  {title || 'Title'}
                </div>
                <div style={{ color: '#48565B' }} className="text-sm col-span-1 text-right">
                  22 phút trước
                </div>
              </div>
              <div>
                <span
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    fontSize: '15px',
                    lineHeight: '19px',
                  }}
                  className="overflow-hidden text-black description whitespace-pre-line"
                >
                  {content || 'content'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="bg-no-repeat bg-contain"
        style={{
          padding: '0 10px',
          height: DEVICE_INFO[device.code].height,
          width: DEVICE_INFO[device.code].width,
          backgroundImage: `url(${BackgroundLockScreenAndroid})`,
        }}
      >
        <div style={{ paddingTop: '221px' }}>
          <div
            className="flex items-center bg-white"
            style={{
              padding: '20px 15px',
              borderRadius: 28,
              boxShadow:
                '0px 0px 0.664039px rgba(0, 0, 0, 0.211221), 0px 1.32808px 0.664039px rgba(0, 0, 0, 0.12)',
            }}
          >
            <div style={{ width: '38px', height: '38px' }} className="mr-3">
              {icon ? <img src={icon} alt="" /> : <IconOnelife />}
            </div>
            <div className="flex-1">
              {/* <div className="flex items-center justify-between "> */}
              <div style={{ fontSize: '11px', lineHeight: '13px' }} className="grid grid-cols-3">
                <div className="font-semibold truncate col-span-2 mr-2">{title || 'Title'}</div>
                <div style={{ color: '#48565B' }} className=" col-span-1 text-right ">
                  <div className="relative inline-block">
                    <div>22 phút trước</div>
                    <div
                      className="absolute bg-black rounded-full"
                      style={{ width: '3px', height: '3px', left: '-8px', top: '4px' }}
                    ></div>
                  </div>
                </div>
              </div>
              <div>
                <span
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    fontSize: '13px',
                    lineHeight: '18px',
                  }}
                  className="overflow-hidden text-black"
                >
                  {content || 'content'}
                </span>
              </div>
            </div>
            <div style={{ marginLeft: '14px' }}>
              <RiArrowDownSLine
                style={{ background: '#F3F0F4' }}
                size={23}
                className="rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LockScreenView
