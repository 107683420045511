import { Button, Form, Input, message, Select, Skeleton, AutoComplete } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState, useCallback } from 'react'
import { Schema } from 'CampaignTypes'
import { getMessageParams } from 'Services/store-farming'
import FormItem from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/FormItem'
import { get, set } from 'lodash'
import _ from 'lodash'
import { getParameterSegment, getZnsTemplateByIdNew } from 'Services/new-cj'
export type ParamType = { parameter_code: string }
export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}

const FormItemZNS = ({
  onSubmit,
  audience_id,
  value,
}: {
  onSubmit: (value: any) => void
  audience_id?: any
  value?: any
}) => {
  const [form] = Form.useForm()
  const { content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [dimensionListing, setDimensionListing] = useState<string[]>([])
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [opt, setOpt] = useState<any>()
  const [loadingDimension, setLoadingDimension] = useState(false)
  const [template_data, setTemplateData] = useState<any[]>([])
  const handleFetchData = async () => {
    const paramsResp = await getMessageParams()
    const paramData: ParametersType[] = paramsResp.data
    const flat = paramData?.map((item) => {
      return item.parameters.map((i) => i.parameter_code?.replace(/{{|}}/gi, ''))
    })
    var listDefaultDimensions: any = []
    flat?.forEach((item) => {
      listDefaultDimensions = [...listDefaultDimensions, ...item]
    })
    setTemplateData(listDefaultDimensions)
  }
  const formatArrayToObject = (array) => {
    return array.reduce((acc, { key, name }) => {
      acc[key] = name
      return acc
    }, {})
  }
  const formatObjectToArray = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      console.error('Input is not a valid object')
      return []
    }

    return Object.entries(obj).map(([key, name]) => ({
      key,
      name,
    }))
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  useEffect(() => {
    const getDimensionListing = async () => {
      setLoadingDimension(true)
      try {
        const resp = await getParameterSegment()
        const filterKey = resp?.data?.parameters?.filter(
          (item) => ![...dimensionListing, ...template_data].includes(item)
        )
        setDimensionListing([...dimensionListing, ...filterKey])
        setState({
          key: 'content',
          value: {
            ...content,
            template_data: value?.template_data ? value?.template_data : {},
          },
        })
      } catch (err: any) {
        setLoadingDimension(false)
        setDimensionListing([])
        console.log('something went wrong!')
      }
      setLoadingDimension(false)
    }
    if (audience_id) {
      getDimensionListing()
    }
  }, [audience_id, value])

  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateByIdNew()
        setTemplateId(resp?.data)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    getTemplate()
  }, [selectedTemplateId, audience_id])

  useEffect(() => {
    form.setFieldsValue({ template_id: templateId?.[0]?.template_id })
    setSelectedTemplateId(templateId?.[0]?.template_id)
    setState({
      key: 'content',
      value: {
        ...content,
        template_id: templateId?.[0]?.template_id,
        template_name: templateId?.[0]?.template_name,
      },
    })
  }, [templateId, loadingTemplate, selectedTemplateId, audience_id])

  useEffect(() => {
    if (templateId?.length) {
      setState({
        key: 'content',
        value: {
          ...content,
          template_name: templateId?.filter((item) => item.template_id === selectedTemplateId)[0]
            .template_name,
        },
      })
    }
  }, [selectedTemplateId])

  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
  }

  const onChangeValue = (name: string, value?: string) => {
    setSelectedTemplateId(value)
    form.setFieldsValue({ [name]: value })

    setState({ key: 'isContentValidated', value: false })
    setState({ key: 'content', value: { ...content, [name]: value, template_data: [] } })
  }

  const onChangeDimension = (name, value) => {
    form.setFieldsValue({ [name]: value })
    if (formatObjectToArray(content?.template_data)?.filter((item) => item.name === name)?.length) {
      const filterContent = formatObjectToArray(content?.template_data)?.filter(
        (i) => i.name !== name
      )
      const template_data = [...filterContent, { key: name, name: value }]
      setState({
        key: 'content',
        value: { ...content, template_data: formatArrayToObject(template_data) },
      })
    } else {
      const template_data = [
        ...formatObjectToArray(content?.template_data),
        { key: name, name: value },
      ]
      setState({
        key: 'content',
        value: { ...content, template_data: formatArrayToObject(template_data) },
      })
    }
  }
  const getValueContentParams = (name) => {
    const getItem = formatObjectToArray(content?.template_data)?.filter((item) => item.key === name)

    if (getItem?.length) {
      return getItem[0]?.name
    } else {
      return undefined
    }
  }
  const getDefaultValueContentParams = (name) => {
    const getItem = formatObjectToArray(value?.template_data)?.filter((item) => item.key === name)

    if (getItem?.length) {
      return getItem[0]?.name
    } else {
      return undefined
    }
  }

  if (loadingTemplate)
    return (
      <>
        <Skeleton active></Skeleton>
      </>
    )

  return (
    <div>
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={content}
        scrollToFirstError
        form={form}
      >
        <Form.Item key={'template_id'} label={'Template ID'} name={'template_id'}>
          <Select
            value={form.getFieldValue(content && content['template_id']) || selectedTemplateId}
            onChange={(value) => onChangeValue('template_id', value)}
            options={templateId?.map((item) => ({
              value: item?.template_id || '',
              label: item?.template_id || '',
            }))}
          />
        </Form.Item>
        {loadingDimension ? (
          <Skeleton active></Skeleton>
        ) : (
          <>
            {templateId?.length
              ? Object?.entries(
                  templateId?.filter((i) => i?.template_id === selectedTemplateId)[0]?.[
                    'template_data'
                  ]
                )
                  ?.map(([key, value]) => {
                    return {
                      key,
                      name: value,
                    }
                  })
                  ?.map((item, index) => (
                    <Form.Item key={item?.key} label={`${item?.key}`}>
                      {/* <Input disabled value={item?.name} /> */}
                      <AutoComplete
                        options={
                          dimensionListing?.map((item) => ({
                            value: item,
                          })) || []
                        }
                        placeholder="Input value or select from list"
                        filterOption={(inputValue, option) =>
                          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(value) => {
                          onChangeDimension(item?.key, value)
                        }}
                        onBlur={(value) => {
                          onChangeDimension(item?.key, (value.target as any)?.value)
                        }}
                        defaultValue={getDefaultValueContentParams(item?.key) as any}
                        // value={getValueContentParams(item.key) as any}
                      />
                    </Form.Item>
                  ))
              : null}
          </>
        )}
      </Form>
      <Button
        type="primary"
        onClick={() => {
          onSubmit({
            ...content,
            template_id: templateId?.filter((i) => i?.template_id === selectedTemplateId)[0]
              ?.template_id,
          })
        }}
      >
        Save
      </Button>
    </div>
  )
}

export default FormItemZNS
