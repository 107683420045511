import { Button, Checkbox, Input, Modal } from 'antd'
import React, { useState } from 'react'
import axios from 'Utils/axios'
import { useStoreState } from 'easy-peasy'

export default function useTestOnDevice({ onSubmit }) {
  const { selected_channel, selected_placement, audience_id, content } = useStoreState(
    (state) => state.campaignModule
  )
  const [open, setOpen] = useState(false)
  const [devices, setDevices] = useState<{ phone: string; selected: boolean }[]>([])
  const [input, setInput] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const onAddDevice = (phone: string) => {
    setDevices((prev) => [...prev, { phone, selected: true }])
  }

  const onRemove = (index: number) => {
    const _devices = [...devices]
    _devices.splice(index, 1)
    setDevices(_devices)
  }

  const onSelect = (newValue: { phone: string; selected: boolean }, index: number) => {
    const _devices = [...devices]
    _devices.splice(index, 1, newValue)
    setDevices(_devices)
  }

  const handleTest = () => {
    const payload = {
      channel_id: selected_channel?.id,
      placement_id: selected_placement?.id,
      audience_segment_id: audience_id,
      whitelist: devices.map((device) => ({ phone: device.phone })),
      content: content,
    }

    if (onSubmit) {
      onSubmit(payload)
      return
    }

    try {
      axios.post('/tests/campaigns/send-notification', payload)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    devices,
    handleTest,
    onSelect,
    onAddDevice,
    onRemove,
    setOpen,
    open,
    setInput,
    input,
    setIsEditing,
    isEditing,
  }
}
