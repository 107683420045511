import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'

import { formatCampaignErrors } from './helpers'
import {
  createCampaign,
  updateCampaign,
  createCampaignZaloGroup,
  createContent,
  addCampaignContent,
  addCampaignSchedule,
  publishCampaign,
} from 'Services/campaigns'
import Header from './Components/Header'
import Body from './Components/Body'
import ProgressBar from './Components/ProgressBar'
import { addTagToCampaign } from 'Services/campaign-tags'

export default function NewCampaign() {
  const setCampaignState = useStoreActions((action: any) => action.campaignModule.setState)
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')
  const history = useHistory()
  const {
    name,
    description,
    selected_placement,
    content,
    mode,
    start_time,
    start_date,
    end_time,
    end_date,
    days,
    start_at,
    audience_id,
    daily_budget,
    bid_amount,
    kpi,
    campaign_tag_ids,
    zalo_groups,
  } = useStoreState((state: any) => state.campaignModule)

  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)
  const [errors, setErrors] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableCreatePublish, setDisableCreatePublish] = useState(false)
  const [action, setAction] = useState('save')

  const onCreateCampaign = async () => {
    try {
      const campaignResp = await createCampaign({
        name,
        description,
        daily_budget,
        bid_amount,
        kpi,
      })

      setState({ key: 'campaign_id', value: campaignResp.data.id })

      const contentResp = await createContent({
        content,
        placement_id: selected_placement.id,
      })

      if (selected_placement.code === 'zalo_group') {
        await createCampaignZaloGroup({ campaign_id: campaignResp.data.id, zalo_groups })
      } else {
        await updateCampaign({
          campaign_id: campaignResp.data.id,
          audience_segment_id: audience_id,
        })
      }
      await addCampaignContent({
        campaign_id: campaignResp.data.id,
        content_id: contentResp.data.id,
      })
      await addCampaignSchedule({
        campaign_id: campaignResp.data.id,
        schedule: {
          mode,
          published: false,
          start_time: start_date ? `${start_date} ${start_time}:00` : '',
          end_time: end_date ? `${end_date} ${end_time}:00` : '',
          days,
          start_at: start_at ? `${start_at}:00` : '',
        },
      })
      return { id: campaignResp.data.id }
    } catch (err: any) {
      throw err
    }
  }

  const handleCreateCampaign = async () => {
    setIsSubmitting(true)
    try {
      await onCreateCampaign()
      message.success('Created campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePublishCampaign = async () => {
    setAction('publish')
    setDisableCreatePublish(true)
    try {
      const resp = await onCreateCampaign()
      await publishCampaign({ campaign_id: resp?.id, audience_id })
      //await addTagToCampaign({ campaign_id: resp?.id, tag_ids: campaign_tag_ids })
      setAction('save')
      message.success('Published campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
      setDisableCreatePublish(false)
    }
  }
  useEffect(() => {
    setCampaignState({ key: 'audience_id', value: id })
  }, [])
  useEffect(() => {
    setBreadCrumb([
      { title: 'Campaign List', path: '/campaigns' },
      { title: 'New Campaign', path: '/campaigns/new' },
    ])
  }, [setBreadCrumb])

  return (
    <div>
      <Header
        disablePublish={disableCreatePublish}
        handleCreateCampaign={handleCreateCampaign}
        handlePublishCampaign={handlePublishCampaign}
        errors={errors}
        isSubmitting={isSubmitting}
      />
      <div className="py-4 px-7 bg-white">
        <ProgressBar />
      </div>

      <div>
        <Body />
      </div>
    </div>
  )
}
