import React, { useState, useMemo, useEffect } from 'react'
import { BarChartOutlined, EyeOutlined } from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Modal } from 'antd'
import { Sync as RefreshIcon } from '@styled-icons/boxicons-regular/Sync'

import LoadingIcon from 'Components/LoadingIcon'
import ErrorMessage from 'Components/ErrorMessage'
import Table from 'Components/Table2.0'

import { setSegmentData } from 'Pages/Audiences/Detail/helpers'
import NewSegmentNavigation from 'Components/AudienceSelect/NewSegmentNavigation'

import useAudienceScreen from './useAudienceScreen'
import { getAudienceSegment } from 'Services/customer-segments'
import { COLUMNS } from './constants'

type props = {
  onSelectAudienceId: (id: number) => void
  selectedAudienceId: number
}

const handleStatistics = (audienceId) => {
  window.open(`/analytics/segment360/${audienceId}`, '_self')
}

const Audience: React.FC<props> = ({ onSelectAudienceId, selectedAudienceId }) => {
  const [modalScreen, setModalScreen] = useState<'TEMPLATE' | 'BLANK' | 'SELECT' | ''>('')
  const [errors, setErrors] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAudience, setSelectedAudience] = useState<{ name?: string } | null>(null)
  const [filters, setFilters] = useState<any>({
    segment_status: 'published',
  })

  const setCustomerSegmentState = useStoreActions((action: any) => action.customerSegments.setState)
  const resetCustomerSegmentState = useStoreActions(
    (action: any) => action.customerSegments.resetState
  )

  const ModalScreen = useAudienceScreen({ modalScreen, setModalScreen, onSelectAudienceId })

  useEffect(() => resetCustomerSegmentState, [resetCustomerSegmentState])

  useEffect(() => {
    ;(async () => {
      if (selectedAudienceId) {
        const resp = await getAudienceSegment(selectedAudienceId)
        setSelectedAudience(resp.data)
      }
    })()
  }, [selectedAudienceId])

  const renderTable = useMemo(() => {
    const handleTableChange = (tablePagination, tableFilter, sorter) => {
      setFilters({
        ...filters,
        per_page: tablePagination.pageSize,
        page: tablePagination.current,
        order_by: sorter.order
          ? sorter.order === 'ascend'
            ? sorter.field
            : `-${sorter.field}`
          : null,
      })
    }

    return (
      <Table
        fetchFrom={{ url: '/audience-segments/', key: 'results' }}
        search={{ keyword: searchTerm, onSearch: setSearchTerm }}
        columns={COLUMNS}
        rowClassName={(record) => (record.id === selectedAudienceId ? 'selected-row' : '')}
        dropdown={{ value: filters }}
        handleTableChange={handleTableChange}
        rowActions={{
          onRowClick: (record) => {
            onSelectAudienceId(record.id)
          },
          actions: [
            {
              icon: <BarChartOutlined />,
              attribute: { type: 'highlight', label: 'Performance' },
              onClick: (record) => handleStatistics(record?.id),
            },
            {
              icon: <EyeOutlined />,
              attribute: { label: 'View' },
              onClick: async (record) => {
                setModalScreen('SELECT')
                setIsLoading(true)
                try {
                  const resp = await getAudienceSegment(record.id)
                  setSegmentData(resp.data, setCustomerSegmentState)
                  setCustomerSegmentState({ key: 'need_insights_refresh', value: true })
                } catch (err: any) {
                  setErrors(err.message || 'Something went wrong')
                }
                setIsLoading(false)
              },
            },
          ],
        }}
      />
    )
  }, [setCustomerSegmentState, selectedAudienceId, onSelectAudienceId, filters, searchTerm])

  return (
    <div className="AudienceStep">
      <NewSegmentNavigation
        onSelectScreen={(key) => {
          resetCustomerSegmentState()
          setModalScreen(key)
        }}
      />
      <div>
        <div className="mb-4 text-gray_13 font-semibold text-lg mt-6 flex justify-between">
          <span>Saved segments</span>
          <span className="cursor-pointer transform rotate-90 text-brand_primary">
            <RefreshIcon
              onClick={() => setFilters({ ...filters, isRefreshed: true })}
              width={20}
              height={20}
            />
          </span>
        </div>
        {selectedAudience ? <div>Selected audience: {selectedAudience?.name}</div> : null}
        {renderTable}
      </div>
      <Modal
        className="audience-step-modal"
        width={1200}
        visible={modalScreen ? true : false}
        footer={null}
        closable={false}
        onCancel={() => setModalScreen('')}
      >
        <div className="w-full">
          {errors ? (
            <ErrorMessage message={errors} />
          ) : isLoading ? (
            <div className="flex w-full justify-center p-5">
              <LoadingIcon style={{ width: 40 }} />
            </div>
          ) : (
            ModalScreen
          )}
        </div>
      </Modal>
    </div>
  )
}

export default Audience
