import React from 'react'
import { Typography, Tooltip, Switch } from 'antd'
import StatusTag from 'Components/StatusTag'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'
import InUsedCampaign from 'Pages/Audiences/InUsedCampaign'
import Switcher from '../Detail/Components/Swicher'

const { Text } = Typography

export const STATUS = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
  { label: 'Creating', value: 'creating' },
  { label: 'Updating', value: 'updating' },
  { label: 'All', value: '' },
]

export const SOURCE = [
  { label: 'Kariba', value: 'kariba' },
  // { label: 'Facebook', value: 'facebook' },
]

export const USED_IN = [
  { label: 'Active campaign', value: 'active' },
  { label: 'Inactive campaign', value: 'inactive' },
  { label: 'Not in use', value: 'not_in_use' },
]

export const FILTER_TABS = (creators, filters, setFilters) => {
  return [
    {
      label: 'Status',
      value: 'segment_status',
      options: STATUS,
      type: 'TAB' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'User source',
      value: 'user_source',
      options: SOURCE,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Used in',
      value: 'used_in',
      options: USED_IN,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Created by',
      value: 'created_by',
      options: creators,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
  ]
}
