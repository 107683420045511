import React, { useCallback, useEffect, useState } from 'react'
import { Tree, Table, Alert, Button, message, Empty, Select } from 'antd'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import axios from 'axios'

import TableHeader from 'Components/Table2.0/Components/Header'
import useTable from 'Components/Table2.0/Components/useTable'
import LoadingIcon from 'Components/LoadingIcon'
import {
  CATEGORIES,
  COLUMNS,
  PRODUCT_TAGS,
  BRAND,
  SUPPLIER,
  SAMPLE_ITEM_DATA,
  SOURCE_OPTIONS,
} from './constants'
import { Category, ProductItem as ProductItemType } from 'ProductListingTypes'
import DatePicker from 'Components/DatePicker'
import { ReactComponent as IconGrid } from 'Assets/images/icons/grid.svg'
import { ReactComponent as IconListView } from 'Assets/images/icons/listView.svg'
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg'

import ProductItem from './ProductItem'
import {
  getBrandFilterList,
  getProductListingCategoryList,
  getProductDetailList,
} from 'Services/Analytics/product-analytics'
import moment from 'moment'
import { debounce, values } from 'lodash'
const { Option } = Select

const ProductListing = () => {
  const handleMTD = (format: string) => {
    const arrDate = format.split('-')
    const formatDate = `${arrDate[0]}-${arrDate[1]}-01`
    return moment().format(formatDate)
  }
  const [dataSource, setDataSource] = useState('pcm')
  const [view, setChangeView] = useState('grid')
  const [sampleGridData, setSampleGridData] = useState<ProductItemType[]>(SAMPLE_ITEM_DATA)
  const [brandFilterList, setBrandFilterList] = useState<{ label: string; value: string }[]>([])
  const [supplierFilterList, setSupplierFilterList] = useState<{ label: string; value: string }[]>(
    []
  )
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [productDetailList, setProductDetailList] = useState<ProductItemType[]>(SAMPLE_ITEM_DATA)
  const [isLoading, setIsLoading] = useState(false)
  const [cateId, setCateId] = useState<any>('root')
  const [isProductListLoading, setIsProductListLoading] = useState(true)
  const [page, setPage] = useState(2)
  const [testPage, setTestPage] = useState(1)
  const [cateIdChanged, setCateIdChanged] = useState('')
  const [brand, setBrand] = useState<any>('')
  const [supplier, setSupplier] = useState<any>('')
  const [date, setDate] = useState<any>({
    from_date: handleMTD('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  })
  const [keyWord, setKeyword] = useState('')
  const [isQuerying, setIsQuerying] = useState(false)
  const [perpage, setPerpage] = useState(16)
  const [test, setTest] = useState(false)
  const [disable, setDisable] = useState(false)
  const [toggle, setToggle] = useState<boolean>(true)
  const [count, setCount] = useState(0)

  const handleBrandData = async () => {
    try {
      const resp = await getBrandFilterList()
      const tempBrandData = resp.data
        ?.filter((i) => i?.name === 'brands')?.[0]
        .data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      const tempSupplierData = resp.data
        ?.filter((i) => i?.name === 'suppliers')?.[0]
        .data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      setBrandFilterList(tempBrandData)
      setSupplierFilterList(tempSupplierData)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }

  const handleCateData = async () => {
    setIsLoading(true)

    try {
      const resp = await getProductListingCategoryList({ source: dataSource })
      //console.log('testing: ', resp.data.node)

      //console.log('converted data: ', tempData)
      setCategoryList(resp?.data?.children)
      setIsLoading(false)

      //setBrandFilterList(tempData)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }
  useEffect(() => {
    handleBrandData()
    handleCateData()
  }, [dataSource])

  useEffect(() => {
    setIsProductListLoading(false)
    setIsQuerying(true)
    let cancelTokenSource = axios.CancelToken.source()
    setTest(false)

    const handleProductListData = async () => {
      try {
        const resp = await getProductDetailList(
          cateId,
          dataSource,
          1,
          perpage,
          100,
          brand,
          supplier,
          date.from_date,
          date.to_date,
          keyWord,
          '',
          cancelTokenSource
        )
        const data = await resp.data.results
        setProductDetailList(data)
        setCount(resp.data?.count)
        data?.length == 0 ? setIsProductListLoading(true) : setIsProductListLoading(false)
        setCateIdChanged('')
        setIsQuerying(false)
      } catch (err: any) {
        //message.error('Something went wrong')
      }
    }
    handleProductListData()
    return () => {
      cancelTokenSource.cancel('cancelled')
    }
  }, [cateId, keyWord, brand, supplier, date, perpage, dataSource])

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      setKeyword(nextValue)
    }, 1000),
    []
  )

  const fetchProducts = async (p, order) => {
    //console.log('fetch products called')
    const resp = await getProductDetailList(
      cateId,
      dataSource,
      p,
      perpage,
      19000,
      brand,
      supplier,
      date.from_date,
      date.to_date,
      keyWord,
      order
    )
    const data = await resp.data.results
    return data
  }

  const { params, setParams, data, loading, errors, total } = useTable({
    url: 'products/analytics',
    key: 'results',
  })

  const onLoad = async () => {
    setDisable(true)
    const productLists = await fetchProducts(page, '')
    setProductDetailList([...productDetailList, ...productLists])
    setPage(page + 1)
    productLists && setDisable(false)
  }
  const onTableChange = async (tablePagination, _, sorter) => {
    setIsQuerying(true)
    setPerpage(tablePagination.pageSize)
    setTestPage(tablePagination.current)
    const productLists = await fetchProducts(tablePagination.current, sorter.field)
    setProductDetailList(productLists)
    productLists && setIsQuerying(false)
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage: count <= perpage ? false : true,
    onLoadMore: onLoad,
    disabled: disable,
    rootMargin: '0px 0px 400px 0px',
  })

  return (
    <div className="ProductListing">
      {errors && <Alert message={errors} type="error" />}
      <div className="flex items-center justify-between mb-4">
        <div className="">
          <TableHeader
            search={{
              keyword: test ? '' : params?.query,
              onSearch: (v) => {
                //setParams({ ...params, query: v })
                //setKeyword(v)

                debounceDropDown(v)
              },
            }}
            dropdown={{
              fields: [
                {
                  label: 'Brands',
                  value: 'brands',
                  options: brandFilterList,
                  type: 'DROPDOWN',
                  onClick: ({ key, value }) => {
                    setParams({ ...params, [key]: value })
                    setBrand(value.toString())
                  },
                },
                {
                  label: 'Suppliers',
                  value: 'suppliers',
                  options: supplierFilterList,
                  type: 'DROPDOWN',
                  onClick: ({ key, value }) => {
                    setParams({ ...params, [key]: value })
                    setSupplier(value.toString())
                  },
                },
              ],
              value: {
                brands: params?.brands,
                suppliers: params?.suppliers,
              },
            }}
          />
        </div>
        <div className="flex items-center">
          <Select
            className="w-[200px] h-full text-base rounded-md text-primary_text "
            value={dataSource || undefined}
            defaultValue={'pcm'}
            placeholder="Content Source"
            onChange={(v) => setDataSource(v)}
            getPopupContainer={(trigger) => trigger.parentNode}
            size="large"
          >
            {SOURCE_OPTIONS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <div className={`iconProductListing ${view == 'list' && 'active'}`}>
            <div onClick={() => setChangeView('list')} className="p-2 ml-2 rounded">
              <IconListView />
            </div>
          </div>
          <div className={`iconProductListing ${view == 'grid' && 'active'}`}>
            <div onClick={() => setChangeView('grid')} className="p-2 mr-2 rounded ">
              <IconGrid />
            </div>
          </div>
          <div>
            <DatePicker
              onChange={({ from_date, to_date }) => {
                // console.log({ from_date, to_date })
                setDate({ from_date: from_date, to_date: to_date })
              }}
              value={{ from_date: date?.from_date, to_date: date?.to_date }}
            />
          </div>
        </div>
      </div>
      <div className={`flex mr-1 rounded ${view === 'grid' ? 'shadow-full' : ''}`}>
        <div style={{ backgroundColor: '#f5f5f5' }}>
          <div
            className="items-center justify-center bg-white cursor-pointer"
            style={{
              display: toggle ? 'none' : 'flex',
              width: 24,
              height: 40,
              fontSize: 18,
              transform: 'translateX(-33px)',
              borderRadius: '0 4px 4px 0',
              top: 255,
              position: 'absolute',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
            }}
            onClick={() => setToggle(true)}
          >
            {`>`}
          </div>
        </div>
        <div
          className="relative bg-white left-corner-radius"
          style={{ width: 304, display: toggle ? '' : 'none' }}
        >
          <div
            style={{ height: 50, background: 'var(--table_header)' }}
            className="flex justify-between p-4 text-sm border-b top-left-corner-radius text-secondary_text"
          >
            <div className="flex">
              Category
              <IconSort className="ml-2 cursor-pointer"></IconSort>
            </div>
            <div
              style={{ cursor: 'pointer', fontSize: 18 }}
              onClick={() => setToggle(false)}
            >{`<`}</div>
          </div>

          <Tree
            treeData={categoryList}
            onSelect={(checkedKeys) => {
              //setParams({ ...params, category_id: checkedKeys[0] })
              setCateId(checkedKeys[0])
              setCateIdChanged('changed')
              setKeyword('')
              setPage(1)
              setTest(true)
              //setProductDetailList(data)
            }}
          />
        </div>

        {view === 'grid' ? (
          isQuerying === false ? (
            !isProductListLoading ? (
              <div className="flex-1 overflow-scroll Products-grid-container" ref={rootRef}>
                <div className="Products-grid-title">Product Info</div>
                <div className="grid flex-1 Products-grid">
                  {productDetailList.map((item, index) => {
                    return <ProductItem product={item} key={index}></ProductItem>
                  })}
                </div>

                {count <= perpage ? null : (
                  <div ref={sentryRef}>
                    <LoadingIcon style={{ margin: 'auto' }} />
                  </div>
                )}
              </div>
            ) : (
              <Empty style={{ width: '100%' }}></Empty>
            )
          ) : (
            <LoadingIcon style={{ margin: 'auto', marginBottom: '50%' }}></LoadingIcon>
          )
        ) : isQuerying === false ? (
          <Table
            pagination={{
              pageSizeOptions: ['100'],
              showSizeChanger: true,
              pageSize: perpage || 100,
              total: 19000 || undefined,
              current: testPage || 1,
            }}
            loading={{
              spinning: false,
              indicator: <LoadingIcon style={{ margin: 'auto', marginLeft: '50%' }} />,
            }}
            columns={COLUMNS}
            dataSource={productDetailList}
            onChange={onTableChange}
            onRow={(record: ProductItemType) => {
              return {
                onClick: () => {
                  window.open(`/analytics/product360/${record?.barcode}`, '_blank')
                },
              }
            }}
            scroll={{ x: 2300 }}
          />
        ) : (
          <LoadingIcon style={{ margin: 'auto' }}></LoadingIcon>
        )}
      </div>
    </div>
  )
}
export default ProductListing
