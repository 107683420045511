import React, { useState, useEffect } from 'react'
import { Modal, Button, Tabs, Input, List, Typography } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { useStoreActions } from 'easy-peasy'

import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import './index.scss'
import { CJModalPopup } from 'Components/CJNode'
import { APP_SCREENS_OPTIONS, TIME_VALUE_OPTIONS } from './constants'
export const appInPageSchema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required(),
  imageUrl: yup.string().required(),
  ctaTitle: yup.string().required(),
  // ctaRedirectLink: yup.string().required(),
  ctaRedirectDeepLink: yup.string().required(),
  isStopNextCondition: yup.boolean(),
  screens: yup.array(),
  timeFormat: yup.string(),
  timeValue: yup.number(),
  utmSource: yup.string().required(),
  utmMedium: yup.string().required(),
  utmCampaign: yup.string().required(),
})

const initialValues = {
  title: '',
  content: '',
  imageUrl: '',
  ctaTitle: '',
  // ctaRedirectLink: '',
  ctaRedirectDeepLink: '',
  isStopNextCondition: false,
  screens: [],
  timeFormat: '',
  timeValue: undefined,
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
}

const schemas = [
  { name: 'title', type: 'dynamic_content', label: 'Title', placeholder: 'Enter Title' },
  {
    name: 'content',
    type: 'dynamic_content',
    label: 'Description',
    placeholder: 'Enter Description',
  },
  {
    name: 'imageUrl',
    type: 'image',
    label: 'Image',
  },
  {
    name: 'ctaTitle',
    type: 'string',
    label: 'Button label',
    placeholder: 'Enter Button label',
  },
  // {
  //   name: 'ctaRedirectLink',
  //   type: 'string',
  //   label: 'CTA Redirect Link',
  // },
  {
    name: 'ctaRedirectDeepLink',
    type: 'string',
    label: 'Navigate to',
    placeholder: 'http://bit.ly/abc',
  },

  {
    name: 'screens',
    type: 'multi_select',
    label: 'Screens',
    options: APP_SCREENS_OPTIONS,
    placeholder: 'Select Screens',
  },
  {
    name: 'timeValue',
    type: 'string',
    label: 'End time',
    placeholder: 'Enter Time Value',
    inputType: 'number',
  },
  {
    name: 'timeFormat',
    type: 'select',
    label: ' ',
    options: TIME_VALUE_OPTIONS,
    placeholder: 'Select Time Format',
  },
  {
    name: 'isStopNextCondition',
    type: 'checkbox',
    label: ' ',
    valueTitle: 'Next Condition',
    description: 'The end time will be based on whichever time comes first',
  },

  {
    name: 'utmSource',
    type: 'string',
    label: 'UTM Source',
  },
  {
    name: 'utmMedium',
    type: 'string',
    label: 'UTM Medium',
  },
  {
    name: 'utmCampaign',
    type: 'string',
    label: 'UTM Campaign',
  },
]

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
}

const AppInPage: React.FC<Props> = (props) => {
  const {
    isPopoverVisible,
    value,
    initData,
    setIsPopoverVisible,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  // const { headline, promo_title, promo_content, go_back_button_label, go_back_button_url } =
  //   initData
  const CJPopupModal = React.useContext(CJModalPopup)

  const onCloseModal = () => {
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  const onSubmit = async (values: any) => {
    // post field values and HTML DOM for BE usage
    onAddNewNode && (await onAddNewNode('appInPage', values))
    onUpdateNode && (await onUpdateNode(values))
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
  }, [value])

  useEffect(() => {
    setIsPopoverVisible(false)
    // setIsModalVisible(true)
    CJPopupModal.modalVisibleOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => CJPopupModal.modalVisibleOff()}
      visible={CJPopupModal?.isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={1200}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`PopupComponent flex ${props.isRunning ? 'is-running' : ''}`}>
        <div className="PopupInputs">
          <CreativeContent
            onSubmit={onSubmit}
            submitText="Save"
            initValues={value || initialValues}
            validationSchema={appInPageSchema}
            fields={schemas}
            onChange={(name: string, value: string) =>
              setPreviewData({
                key: name,
                value: value,
              })
            }
          />
        </div>
        <Preview isPreview={true} type={'appInPage'}></Preview>
      </div>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(AppInPage)
