import React, { useMemo } from 'react'
import { camelCaseToNormalText, formatCartValueConditions } from './constants'
import { Tooltip } from 'antd'
import usePromotionDetail from 'Components/CJPopover/NodeConfigs/PromotionNode/usePromotionDetail'
import moment from 'moment'
type NodePreview =
  | 'virtual'
  | 'spingame'
  | 'randomvoucher'
  | 'codedefault'
  | 'entrance'
  | 'wait'
  | 'waitIn'
  | 'promotion'
  | 'coupon'
  | 'split'
  | 'checkReachability'
  | 'checkUserAttr'
  | 'checkAudienceSegment'
  | 'web_push'
  | 'sms'
  | 'email'
  | 'popup'
  | 'ol_app'
  | 'appPopup'
  | 'webPopup'
  | 'appInPage'
  | 'webInPage'

export default function NodePreview({
  data,
  type,
  audienceName,
}: {
  data: any
  type: string
  audienceName?: string
}) {
  const { promotion: promotionData } = usePromotionDetail(data?.promotionId)

  const renderPreview = useMemo(() => {
    switch (type) {
      case 'entrance':
        return <div>{camelCaseToNormalText(data?.channel) || 'All'}</div>
      case 'checkAudienceSegment':
        return <div>{audienceName}</div>
      case 'checkUserAttr':
        return (
          <Tooltip title={formatCartValueConditions(data?.segments)}>
            <div className=" line-clamp-2">{formatCartValueConditions(data?.segments)}</div>
          </Tooltip>
        )
      case 'checkReachability':
        return <div>{data?.channel}</div>
      case 'wait':
        return (
          <div>
            Wait for {data?.wait_time} {data?.time_format}
          </div>
        )
      case 'waitIn':
        return (
          <div>
            <div>
              Wait in for {data?.wait_time} {data?.time_format}
            </div>
            <div>Interaction: {data?.interaction}</div>
          </div>
        )
      case 'ol_app':
      case 'appPopup':
      case 'webPopup':
        return <div className=" line-clamp-2">{data?.title || data?.titleContent}</div>
      case 'appInPage':
      case 'webInPage':
        return <div className=" line-clamp-2">{data?.title || data?.titleContent}</div>
      // case 'web_push':
      //   return <div>Tết gắn kết, trao yêu thương!</div>
      // case 'email':
      //   return (
      //     <div>
      //       <span className=" text-secondary_text ml-0.5">Title: </span>
      //       <span>Cơ hội mua nhóm giá ưu đãi. Đoạn này chỉ show được 2 line</span>
      //     </div>
      //   )
      case 'promotion':
        return (
          <div>
            <div className=" line-clamp-1">{promotionData?.name}</div>
            <span className=" text-secondary_text">End date: </span>
            {data?.dateTime
              ? moment(data?.dateTime)?.format('DD-MM-YYYY HH:MM:SS')
              : data?.wait_time + ' ' + data?.time_format}
          </div>
        )

      case 'coupon':
        return <div className=" line-clamp-2">{promotionData?.name}</div>
      default:
        return null
    }
  }, [data, promotionData, audienceName])
  return <div className=" text-left text-base mt-3">{renderPreview}</div>
}
