import { Upload, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import FieldWrapper from '../FieldWrapper'

const ImageField = (props) => {
  const { uploadImage, setPreviewData } = useStoreActions(
    (actions) => actions.campaign.creativeContents
  )
  //
  const { setFieldValue, name, values, initialValues, setErrors, setTouched, onChange } = props

  const [fileList, setFileList] = useState([])
  const [state, setState] = useState({})

  useEffect(() => {
    if (fileList.length <= 0 && !!values[name]) {
      const temp = [
        {
          uid: Math.random(),
          name: 'image.png',
          status: 'done',
          url: values[name],
        },
      ]
      setFileList(temp)
    }
  }, [initialValues])

  useEffect(() => {
    props.values[props.name] &&
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: props.values[props.name],
        },
      ])
  }, [])

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (file) => {
    window.open(values[name], '_blank')
  }

  const handleUploadImage = async (info) => {
    //

    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    if (!isJpgOrPng) {
      await setTouched({ [name]: true })
      await setErrors({ [name]: 'Only allow .jpg and .png type' })
      return setFileList([])
    }

    const config = {
      onUploadProgress: (event) => {
        info.onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }

    const url = await uploadImage({
      image: info.file,
      company: process.env.REACT_APP_COMPANY_NAME,
      key: name,
      config: config,
    })

    if (url.errors) {
      await info.onError(url.errors)
      return
    }

    info.onSuccess('Ok')
    // todo: for preview data, remove later
    onChange(name, url)
    setFieldValue(name, url)

    return url
  }

  const onUploadChange = async ({ file, fileList }) => {
    if (file.status === 'error') {
      await setTouched({ [name]: true })
      await setErrors({ [name]: 'Something went wrong' })
      return setFileList([])
    }

    setFileList(fileList)
  }

  const onRemove = () => {
    setFieldValue(name, '')
    setPreviewData({ key: name, value: '' })
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <FieldWrapper {...props} className="ImageFieldComponent">
      {() => (
        <Upload
          // action={handleUploadImage}
          listType="picture-card"
          fileList={fileList}
          onChange={onUploadChange}
          customRequest={handleUploadImage}
          onRemove={onRemove}
          onPreview={handlePreview}
        >
          {fileList.length >= props.max ? null : uploadButton}
        </Upload>
      )}
    </FieldWrapper>
  )
}

ImageField.defaultProps = {
  max: 1,
}

export default ImageField
