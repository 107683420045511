import React, { useState, useEffect } from 'react'
import { message, Modal, Spin, Switch, Typography } from 'antd'
import { filtersType } from 'CustomerSegmentTypes'
import { useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { BarChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ReactComponent as Duplicate } from 'Assets/images/icons/duplicate.svg'
import { ReactComponent as Edit } from 'Assets/images/icons/edit.svg'
import { ReactComponent as Analytic } from 'Assets/images/icons/analytic.svg'
import { ReactComponent as Delete } from 'Assets/images/icons/deleteAudience.svg'

import { formatErrors } from 'Utils'
import { delAudienceSegment, getCreators, updateAudienceSegment } from 'Services/customer-segments'

import Table from 'Components/Table2.0'
import { FILTER_TABS, STATUS } from './constants'
import '../index.scss'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'
import InUsedCampaign from 'Pages/Audiences/InUsedCampaign'
import Switcher from '../Detail/Components/Swicher'

import { setSegmentData } from '../Detail/helpers'
import { getAudienceSegment } from 'Services/customer-segments'
import { set } from 'lodash'

const CustomerSegments = () => {
  const { Text } = Typography
  const history = useHistory()
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  const [updateLoading, setUpdateLoading] = useState(false)

  const handleUpdateCustomerSegment = async ({ id, body }) => {
    setUpdateLoading(true)
    try {
      const resp = await updateAudienceSegment({ id, body, action: 'null' })
      setSegmentData(resp.data, setState)
      message.success('Updated customer segment successfully')
      setUpdateLoading(false)
    } catch (err: any) {
      setUpdateLoading(false)
      // setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
  }

  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      align: 'left',
      render: (audience) => {
        if (audience)
          return (
            <Text className="audience-title font-bold" strong>
              {audience}
            </Text>
          )
        else return 'N/A'
      },
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      width: 100,
      sorter: true,
      render: (tag) => (tag ? capitalizeFirstLetter(tag) : 'N/A'),
    },
    {
      title: 'Last updated',
      dataIndex: 'updated_at',
      sorter: true,
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
      width: 150,
    },
    {
      title: 'Created by',
      dataIndex: 'created_by_detail',
      width: 200,
      render: (creator) =>
        creator ? `${creator?.first_name || ''} ${creator?.last_name || ''}` : 'N/A',
    },
    {
      title: 'Used in',
      dataIndex: 'used_in_detail',
      align: 'left',
      width: 300,
      render: (used_in_detail) =>
        used_in_detail ? <InUsedCampaign used_in_detail={used_in_detail}></InUsedCampaign> : 'N/A',
    },
    {
      title: 'Sync to OL Admin',
      dataIndex: 'is_kafka_publishable ',
      align: 'center',
      width: 150,
      render: (is_kafka_publishable, record) => (
        <Switch
          checked={record?.is_kafka_publishable}
          onChange={async (v) => {
            const bodyData = record?.custom_sql
              ? {
                  name: record?.name,
                  audience_type: record?.audience_type,
                  description: record?.description,
                  custom_sql: record?.custom_sql.replace(/\n/g, ' '),
                  predictive_segment: null,
                  predefined_segment: null,
                  is_kafka_publishable: v,
                  usage_mode: record?.usage_mode,
                }
              : {
                  name: record?.name,
                  audience_type: record?.audience_type,
                  dimensions: JSON.stringify(record?.dimensions),
                  predictive_segment: record?.predictive_segment || null,
                  store_ids: JSON.stringify(record?.store_ids),
                  description: record?.description,
                  predefined_segment: record?.predefined_segment || null,
                  custom_sql: '',
                  segmentation_type: record?.segmentation_typee || null,
                  is_kafka_publishable: v,
                  usage_mode: record?.usage_mode,
                  //segment_temporary_id: segment_temporary_id.split('_')[0] + '_' + Date.now() || '',
                }
            handleUpdateCustomerSegment({ id: record.id, body: bodyData })
          }}
        ></Switch>
      ),
    },
    {
      title: 'Sync daily',
      dataIndex: 'usage_mode',
      align: 'center',
      width: 150,
      render: (usage_mode, record) => {
        const value = usage_mode === 'recurring' ? true : false
        return (
          <Switch
            checked={value}
            onChange={async (v) => {
              const bodyData = record?.custom_sql
                ? {
                    name: record?.name,
                    audience_type: record?.audience_type,
                    description: record?.description,
                    custom_sql: record?.custom_sql.replace(/\n/g, ' '),
                    predictive_segment: null,
                    predefined_segment: null,
                    is_kafka_publishable: record?.is_kafka_publishable,
                    usage_mode: v ? 'recurring' : 'oneoff',
                  }
                : {
                    name: record?.name,
                    audience_type: record?.audience_type,
                    dimensions: JSON.stringify(record?.dimensions),
                    predictive_segment: record?.predictive_segment || null,
                    store_ids: JSON.stringify(record?.store_ids),
                    description: record?.description,
                    predefined_segment: record?.predefined_segment || null,
                    custom_sql: '',
                    segmentation_type: record?.segmentation_typee || null,
                    is_kafka_publishable: record?.is_kafka_publishable,
                    usage_mode: v ? 'recurring' : 'oneoff',
                    //segment_temporary_id: segment_temporary_id.split('_')[0] + '_' + Date.now() || '',
                  }
              handleUpdateCustomerSegment({ id: record.id, body: bodyData })
            }}
          ></Switch>
        )
      },
    },
  ]

  const [filters, setFilters] = useState<filtersType>({
    user_source: [],
    used_in: [],
    created_by: [],
    segment_status: window.location.href.split('tab=')[1] || 'published',
  })
  const setBreadCrumb = useStoreActions((actions: any) => actions.breadcrumb.setBreadCrumb)

  const [searchTerm, setSearchTerm] = useState('')
  const [creators, setCreators] = useState([])

  const handleDelete = async (record, setRows, rows) => {
    Modal.confirm({
      title: (
        <div className="action-prompt-popup">
          <Typography.Title level={4}>Are you sure to delete {record.name}?</Typography.Title>
        </div>
      ),
      onOk: async () => {
        try {
          await delAudienceSegment(record.id)
          message.success('Deleted customer segment')
          setRows(rows.filter((row) => row.id !== record.id))
        } catch (err: any) {
          message.error(formatErrors(err))
          throw new Error(err)
        }
      },
      okText: 'Delete',
      okType: 'danger',
      centered: true,
    })
  }

  useEffect(() => {
    setBreadCrumb([{ title: 'Segments', path: `/customers/segments` }])
  }, [history])

  useEffect(() => {
    const fetchSegmentCreators = async () => {
      try {
        const resp = await getCreators()
        setCreators(
          resp.data.map((creator) => ({
            label: `${creator.first_name} ${creator.last_name}`,
            value: creator.id.toString(),
          }))
        )
      } catch (err: any) {
        message.error(err.message)
      }
    }
    fetchSegmentCreators()
  }, [])

  const handleCreate = async () => {
    history.push('./segments/new')
  }

  const handleEdit = async (record) => {
    history.push(`./segments/${record.id}`)
  }

  const handleDuplicate = async (record) => {
    setFilters({
      ...filters,
      isLoading: true,
    })

    try {
      const resp = await getAudienceSegment(record.id)
      var dataDuplicate = { ...resp.data, name: '[Copy] ' + resp.data.name }

      setSegmentData(dataDuplicate, setState)

      if (resp.data.custom_sql) {
        history.push({ pathname: `./segments/new`, search: `?screen=CUSTOM_SQL` })
      } else {
        history.push({ pathname: `./segments/new`, search: `?screen=BLANK` })
      }
    } catch (err: any) {}

    setFilters({
      ...filters,
      isLoading: false,
    })
  }
  const handleStatistics = async (record) => {
    window.open(`/analytics/segment360/${record.id}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  return (
    <Table
      className="Customer-Segments-table px-8 py-7"
      dropdown={{
        fields: FILTER_TABS(creators, filters, setFilters),
        value: filters,
      }}
      search={{ keyword: searchTerm, onSearch: setSearchTerm }}
      columns={COLUMNS}
      title="Customer Segments"
      fetchFrom={{ url: '/audience-segments/', key: 'results' }}
      handleTableChange={handleTableChange}
      rowActions={{
        onDoubleClick: handleEdit,
        actions: [
          {
            icon: <Analytic />,
            attribute: { type: 'highlight', label: 'Performance' },
            onClick: handleStatistics,
          },
          { icon: <Edit />, attribute: { label: 'Edit' }, onClick: handleEdit },
          {
            icon: <Duplicate />,
            attribute: { label: 'Duplicate' },
            onClick: handleDuplicate,
          },
          {
            icon: <Delete />,
            attribute: { label: 'Delete' },
            onClick: handleDelete,
          },
        ],
      }}
      primaryButton={{ title: 'Create segment', onClick: handleCreate }}
      customLoading={updateLoading}
    />
  )
}
export default CustomerSegments
